// BASE STYLING
// this is the base styling for widget-options bar
// used for all existing toolbar areas
// inner elements & utilities have seperate files
// any color changes need to be added in the color section below
widget-options {
  display: block;
}

.widget-options {
  display: none;

  &--is-visible {
    display: block;
  }

  left: 50%;
  margin: 0 auto;
  position: absolute;
  transform: translateX(-50%);
  user-select: none;
  z-index: layer(widget-options-classname);
  transition: top 0.33s ease;
  // TODO: commented out for performance gains
  // JJ 6-16-2012 / CRT-456
  // transition: transform .2s ease-in-out;

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .nested-view {
    &__item.number.maxWidth .ui-form {
      padding-top: 0;
    }
  }
}

.widget-options__bar {
  align-content: center;
  align-items: center;
  border-radius: 24px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  display: flex;
  height: $widget-options-height;
  padding: 0 12px;
  position: relative;
  text-align: center;
  // min-width modifier
  &--min-width {
    min-width: 252px;
  }
  &--max-width {
    min-width: 288px;
  }
}

// widget options with all necessary options included
.widget-options--top {
  bottom: calc(100% + 12px);
  // extra offset for section menu
  &.widget-options--offset {
    bottom: calc(100% + 30px);
  }
  // pseudo used for buffer area/tolerance
  .widget-options__bar {
    &::before {
      bottom: -20px;
    }
  }

  .nested-view {
    &__item.boolean {
      margin-bottom: 6px;
      margin-top: 6px;
    }
  }

  //divider line
  .nested-widget-field {
    border-bottom: 0;

    &.is-visible {
      border-bottom: 1px solid transparentize($bgdark-primary-grey, 0.6);
    }
  }

  .date-picker {
    border-bottom: 1px solid transparentize($bgdark-primary-grey, 0.6);
  }

  .btn__widget-menu {
    bottom: 0;
    padding-bottom: $widget-options-height;

    &--overlay {
      padding-bottom: 12px;
    }
  }

  .widget-options__bar {
    &::after {
      border-width: 6px 6px 0;
      bottom: -6px;
      // border color located in color section below
    }
  }
  // needed or else animation close looks goofy
  .btn--fill {
    &:not(.is-open) {
      .btn__widget-menu {
        padding-top: 0;
      }
    }
  }
}

.widget-options--bottom {
  padding-top: 12px;
  top: 100%;

  // extra offset for section menu
  &.widget-options--offset {
    top: calc(100% + 30px);
  }
  // pseudo used for buffer area/tolerance
  .widget-options__bar {
    &::before {
      top: -20px;
    }
  }
  //divider line
  .nested-widget-field {
    border-top: 0;

    &.is-visible {
      border-top: 1px solid transparentize($bgdark-primary-grey, 0.6);
    }
  }

  .date-picker {
    border-top: 1px solid transparentize($bgdark-primary-grey, 0.6);
  }

  .btn__widget-menu {
    padding-bottom: 12px;
    padding-top: $widget-options-height;
    top: 0;
    transform: translateZ(0);

    &--overlay {
      padding-top: 0;
    }
  }

  .widget-options__bar {
    &::after {
      border-width: 0 6px 6px;
      top: -6px;
      // border color located in color section below
    }
  }
  // needed or else animation close looks goofy
  .btn--fill {
    &:not(.is-open) {
      .btn__widget-menu {
        padding-bottom: 0;
      }
    }
  }
}

// modifiers for changing positioning depending on how close the element
// is to the edge of the viewport/pane
.widget-options {
  &--left {
    left: 100%;

    .widget-options__bar {
      &::after {
        left: -180px;
      }
    }
  }

  &--right {
    left: 0%;

    .widget-options__bar {
      &::after {
        right: -180px;
      }
    }
  }
}

// WIDGET MENU
// widget menu states used globaly
.btn--fill {
  align-items: center;
  display: flex;
  justify-content: center;

  > i,
  > span {
    cursor: pointer;

    &:not(.color-swatch) {
      line-height: $widget-options-height;
      width: 100%;
      padding: 0 3px;
    }
  }

  > span {
    font-size: calculate-rem(16px);
  }

  &:not(.is-open) {
    .btn__widget-menu {
      max-height: 0;
      transition: max-height 0.3s ease;
      // apply overlay modifier to have menu overlay the toolbar
      &--overlay {
        opacity: 0;
        transition: max-height 0.3s ease, opacity 0.2s;
        z-index: layer(button-menu-closed);
      }
    }

    .site-routes-field {
      max-height: 0;
      opacity: 0;
    }
  }

  &.is-open {
    .site-routes-field {
      transition: opacity 0s 0.15s;
      opacity: 1;
    }
  }
  // this is a hack due to how design wants this icon displayed,
  // if unavailable is selected then it needs to display as bulleted_list
  > .lp-icon-unavailable {
    &::before {
      content: '\e915';
    }
  }
}

.btn__widget-menu {
  border-radius: 24px;
  // top or bottom applied with modifier
  left: 0;
  // TODO: Note max-height is a brilliant trick but hacky.
  // reasoning is the transition timing is based off the actual
  // min-height value. this can cause delaying due to actual height
  // and the difference between min and actual. I like this but
  // might need a modifier for smaller menus if we can determine what
  // uses what!!!
  max-height: $widget-menu-height;
  position: absolute;
  overflow: hidden;
  transition: max-height ease 0.3s;
  width: 100%;
  z-index: layer(button-menu);
  // apply overlay modifier to have menu overlay the toolbar
  &--overlay {
    opacity: 1;
    transition: max-height 0.3s ease, opacity 0.2s;
    z-index: layer(button-menu-overlay);
  }
}

// WIDGET OPTIONS BUTTON
.widget-options__bar__btn {
  align-content: center;
  align-items: center;
  flex: 1 0 auto;
  justify-content: center;
  min-width: $button-width-sm;

  > i,
  > span {
    font-size: 14px;
    font-style: normal;
  }

  &--color {
    flex-basis: 20px;
  }
}

multi-color-picker .btn__widget-menu {
  max-height: 474px;
}

.widget-options__btn--text {
  padding: 0 10px;
}

// GLOBAL COLORING
// color options for menu and text, all states should be included
.widget-options__bar {
  &--dark {
    background-color: $lp-greyDarkest;
    border-color: $lp-greyDarkest;

    .btn--fill {
      > i,
      > span {
        color: $bgdark-primary-grey;
        transition: color 250ms;
        // states
        &:hover {
          color: $white;
        }
      }

      .color-swatch {
        border-color: $leads-grey;
        transition: border-color 250ms;

        &:hover {
          border-color: $white;
        }
      }

      &.is-open {
        > i,
        > span {
          color: $white;
        }

        .color-swatch {
          border-color: $white;
        }
      }

      &.active .text-icon {
        color: $white;
      }
    }

    .menu-list {
      li {
        color: $bgdark-primary-grey;

        &:hover {
          color: $white;
        }

        &.item-active {
          color: $white;
        }
      }
    }

    .btn__widget-menu {
      background-color: $lp-greyDarkest;
    }
  }

  &--lightblue {
    background-color: $lp-light-blue;

    &::after {
      border-color: $lp-light-blue;
    }

    .btn--fill {
      > i,
      > span {
        color: $bglight-secondary-grey;
        transition: color 250ms;
        // states
        &:hover {
          color: $bglight-primary-grey;
        }
      }

      &.is-open {
        > i,
        > span {
          color: $bglight-primary-grey;
        }
      }

      &.active .text-icon {
        color: $white;
      }
    }

    .btn__widget-menu {
      background-color: $lp-light-blue;
    }
  }
}
// css arrow colors for widget menus
.widget-options--bottom {
  .widget-options__bar--dark {
    &::after {
      border-color: transparent transparent $lp-navy transparent;
    }
  }

  .widget-options__bar--lightblue {
    &::after {
      border-color: transparent transparent $lp-light-blue transparent;
    }
  }
}

.widget-options--top {
  .widget-options__bar--dark {
    &::after {
      border-color: $lp-navy transparent transparent transparent;
    }
  }

  .widget-options__bar--lightblue {
    &::after {
      border-color: $lp-light-blue transparent transparent transparent;
    }
  }
}

// select menu styles - drops down from button
// TODO: Once nesting if fully added this should be able to go away
// .widget-options__selectMenu {
//   ul {
//     list-style: none;
//     margin: 0;
//     overflow: hidden;
//     padding: 0;
//     display: flex;
//     transition: all .3s ease;
//   }
//   // individual changes for menus
//   &--align {
//     ul {
//       flex-direction: row;
//       justify-content: center;
//       align-content: center;
//       align-items: center;
//     }
//   }
//   &--level, &--timerType {
//     ul {
//       flex-direction: column;
//       justify-content: center;
//       align-content: center;
//       align-items: center;
//     }
//   }
//   &--listType {
//     ul {
//       justify-content: center;
//       align-content: center;
//       align-items: center;
//     }
//   }
// }

// select level menu
.widget-options__btn--level {
  min-width: $button-width-lg;
}

// select timer type menu
.widget-options__btn--timerType {
  min-width: $button-width-lg;
}

.show-hide-field {
  height: 0;
  overflow: hidden;

  .sections-list {
    padding: 12px 12px 0;

    &__header {
      align-items: center;
      border-bottom: 1px solid $bgdark-secondary-grey;
      color: $bgdark-primary-grey;
      display: flex;
      padding-bottom: 6px;
    }

    &__list {
      margin-top: 6px;
      margin-bottom: 6px;
      max-height: 195px;
      position: relative;
    }

    .title {
      flex: 1 1 60%;
      text-align: left;
    }

    .checkbox-title {
      flex: 0 0 20%;
    }

    .list-item {
      display: flex;
      margin-bottom: 12px;

      &:hover {
        .list-item__title {
          color: $white;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &__title {
        color: $bgdark-primary-grey;
        flex: 1 1 60%;
        text-align: left;
        transition: color 0.2s ease;
      }

      &__checkbox {
        flex: 0 0 20%;

        .ui-checkbox {
          vertical-align: middle;

          &__label {
            width: 100%;

            &::before {
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
}
