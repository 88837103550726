@use 'sass:math';

@keyframes rotateThis {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.integration-header {
  align-items: center;
  display: flex;

  &__icon {
    height: 25px;
    margin-right: 12px;
    width: 25px;
  }
}

.lp-services {
  margin-top: 12px;
}

.info-card-internal {
  // leadpages services classes only for now. Other integration will go to these
  // most likely when multiple integrations per category
  .category {
    padding-bottom: 12px;

    &__title {
      font-weight: 700;
    }
  }

  .lp-services {
    .title__inner {
      .is-selected {
        opacity: 1;
        width: 18px;
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.75rem; //12/16
    margin: 0;
    padding: 0;
    width: 100%;
  }

  form {
    display: inline-block;
    width: 100%;
  }

  li {
    list-style: none;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  .ui-checkbox .ui-checkbox__label::before,
  .ui-radio .ui-radio__label::before {
    background-color: $white;
  }

  &__list-title {
    font-weight: bold;
    padding: 10px 0 0;
  }

  .refresh-list {
    span {
      font-size: 0.75rem;
      vertical-align: middle;
    }

    .lp-icon-sync {
      font-size: 0.75rem;

      &.is-spinning {
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-name: rotateThis;
        animation-timing-function: linear;
      }
    }
  }

  .dropdown-label,
  .tag-list-container__label {
    align-items: center;
    display: flex;
    font-size: 0.75rem;
    justify-content: space-between;
    width: 100%;
  }

  .dropdown {
    background-color: $white;
    border: 1px solid $greyscale-07;
    border-radius: 6px;
    font-size: 0.875rem;
    height: 42px;
    margin: 12px 0;
    overflow: hidden;
    transition: 0.3s height;

    &:focus {
      outline: none;
    }

    &:hover:not(.is-disabled),
    &:focus:not(.is-disabled) {
      border: 1px solid $lp-blue;
    }

    &-select {
      &__item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 6px;
        width: 100%;

        .lp-icon {
          line-height: 1;
        }
      }
    }

    &-list {
      width: 100%;

      &__item {
        cursor: pointer;
        display: flex;
        padding: 6px;
        transition: color 0.3s;
        width: 100%;

        input {
          display: none;
        }

        &:hover,
        &:active,
        &:focus {
          color: $lp-blue;
        }

        &.is-selected {
          &::before {
            color: $lp-blue;
            content: '\e206';
            font-family: 'LeadPages-Icons';
            margin: 0 6px 0 0;
          }
        }
      }
    }

    &.is-closed {
      cursor: pointer;

      .dropdown-list {
        display: none;
      }

      .dropdown-select {
        display: flex;
        padding: 6px;
        position: relative;
      }
    }

    &.is-open {
      height: 135px;

      .dropdown-select {
        display: none;
      }

      .dropdown-list {
        max-height: 135px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 6px;
      }
    }
  }

  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    &--is-selected__integration-name {
      font-weight: 400;
      opacity: 0;
      width: auto;
    }

    &__inner {
      align-items: center;
      font-weight: 700;

      .is-selected {
        color: $lp-blue;
        font-size: 1.125rem; //18/16
        margin: 0 6px 0 1px;
        opacity: 0;
        width: 0;
      }
    }
  }

  .list-inner {
    border-bottom: 1px solid $bgdark-primary-grey;
    margin: 0 0 12px;
    padding: 0;
  }

  .integration {
    &-inner {
      align-items: center;
      color: $bglight-primary-grey;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      padding: 12px 0;
      transition: color 0.3s;
      width: 100%;

      &:hover,
      &:active,
      &:focus {
        color: $lp-blue;
        outline: none;
      }

      &__icon {
        height: 36px;
        margin: 0 12px 0 0;
        width: 36px;
      }

      &__alert {
        color: $lp-red;
        font-size: 1.125rem; //18/16
        margin: 0 0 0 12px;
        transition: color 0.3s;
        width: auto;

        .lp-icon-error {
          margin-top: -4px;
        }

        &:hover,
        &:active,
        &:focus {
          color: $lp-red-hover;
        }
      }

      &__text {
        width: auto;
      }

      &.needs-reauthentication {
        .integration-inner__text {
          opacity: 0.5;
        }

        .integration-inner__icon {
          opacity: 0.5;
        }

        .integration-inner__alert {
          display: flex;
        }
      }
    }
  }

  .slide-toggle {
    &--is-closed {
      .title {
        padding: 0 0 12px;
        transition: 0.2s padding;

        .is-selected {
          opacity: 1;
          transition: opacity 0.5s, width 0.3s;
          transition-delay: 0.3s;
          width: 18px;
        }

        &--is-selected__integration-name {
          opacity: 1;
          transition: opacity 0.3s;
          transition-delay: 0.3s;
        }
      }
    }
  }

  .add-element {
    align-items: center;
    border: 1px dashed $bglight-secondary-grey;
    border-radius: 5px;
    color: $bglight-primary-grey;
    display: flex;
    font-size: 0.75rem; //12/16
    height: 48px;
    margin-bottom: 12px;
    padding: 0 12px;
    text-decoration: none;
    transition: 0.3s border-color, 0.3s color;
    width: 100%;

    &__icon.lp-icon {
      color: $bglight-secondary-grey;
      font-size: 1.125rem; //18/16
      margin: 0 12px 0 0;
    }

    &__container {
      display: flex;
      flex-basis: 100%;
      max-width: 100%;
    }

    &:hover,
    &:active,
    &:focus {
      border-color: $lp-blue;
      color: $lp-blue;

      .add-element__icon.lp-icon {
        color: $lp-blue;
      }
    }

    &-select {
      &__item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 6px;
        width: 100%;
      }
    }

    &-list {
      width: 100%;

      &__item {
        cursor: pointer;
        display: flex;
        padding: 6px;
        transition: color 0.3s;
        width: 100%;

        input {
          display: none;
        }

        &:hover,
        &:active,
        &:focus {
          color: $lp-blue;
        }

        &.is-selected {
          &::before {
            color: $lp-blue;
            content: '\e206';
            font-family: 'Leadpages-Icons';
            margin: 0 6px 0 0;
          }
        }
      }
    }

    &--is-closed {
      cursor: pointer;

      .dropdown-list {
        display: none;
      }

      .dropdown-select {
        display: flex;
        padding: 6px;
        position: relative;
      }
    }

    &--is-open {
      height: 135px;

      .dropdown-select {
        display: none;
      }

      .dropdown-list {
        display: block;
        max-height: 135px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 6px;
        position: relative; //needs this for perfect scrollbar
      }
    }
  }

  .input {
    margin: 12px 0;
    width: 100%;

    &:last-of-type:not(.country-code) {
      margin-bottom: 0;
    }

    &-item {
      border: 1px solid $greyscale-07;
      border-radius: 6px;
      display: flex;
      margin: 12px 0;
      padding: 12px;
      width: 100%;

      &:focus,
      &:active {
        border: 1px solid $lp-blue;
        outline: none;
      }
    }

    &--double {
      display: flex;

      span:first-child {
        margin-right: 12px;
      }
    }

    .is-invalid__indicator {
      opacity: 0;
      transition: opacity 200ms ease;
    }

    &.is-invalid {
      .input-item {
        border: 1px solid $lp-red;

        &:focus,
        &:active {
          border: 1px solid $lp-blue;
        }
      }

      .is-invalid__indicator {
        align-items: center;
        color: $lp-red;
        display: flex;
        opacity: 1;

        .lp-icon {
          font-size: 18px;
          margin: 0 12px 0 0;
        }
      }
    }

    &__label {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .lp-icon {
        color: $greyscale-07;
        cursor: pointer;
        font-size: 18px;
      }
    }

    &__preview {
      height: 18px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &.country-code {
      display: flex;
      font-size: 14px;
      justify-content: space-around;
    }
  }

  &__title {
    border-top: 1px solid $greyscale-09;
    font-size: 0.75rem; //12px
    margin: 24px 0 6px;
    padding: 24px 0 0;

    &:first-child {
      border-top: 0;
      margin: 0;
      padding: 0;
    }
  }

  .field-type {
    flex-basis: math.div(100%, 4);
    list-style: none;
    max-width: math.div(100%, 4);
    padding: 12px 12px 0;

    &__inner {
      color: $bglight-secondary-grey;
      cursor: pointer;
      display: block;
      transition: 0.3s color;

      &:hover,
      &:active,
      &:focus {
        color: $lp-blue;
      }

      &__text {
        align-items: center;
        display: flex;
        font-weight: 100;
        height: 36px;
        justify-content: center;
        line-height: 1;
        text-align: center;
      }
    }

    .lp-icon {
      align-items: center;
      display: flex;
      font-size: 1.625rem; //26/16
      height: 40px;
      justify-content: center;
    }
  }

  &__callout {
    padding: 10px 0;

    &.has-divider {
      border-bottom: 1px solid rgba($bgdark-primary-grey, 0.5);
      margin-bottom: 15px;
      padding-bottom: 20px;
    }
  }

  &__callout-header {
    font-weight: bold;
    margin: 0 0 0.5em;
  }

  &__callout-text {
    font-size: 0.8125rem; //13px

    .ui-link {
      font-size: 0.8125rem;
    }
  }

  &__callout-button {
    margin: 15px 0 10px 0;
  }

  &__callout-header,
  &__callout-text {
    .lp-icon {
      margin-top: -2px;
    }
  }
} //end info-card-internal
