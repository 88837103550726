@import 'components.styled-scrollbar';

@keyframes rotate {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

/* Spinner - alt for range input */
.spinner {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 6px 0;
}

.spinner__label {
  color: $bgdark-primary-grey;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  margin-right: 8px;
}

.spinner__btn {
  background: none;
  border: 0;
  color: $bgdark-primary-grey;
  margin: 0;
  outline: none;
  padding: 0;
  transition: color 0.2s ease-in-out;

  i {
    font-size: 18px;
  }

  &:hover {
    color: $white;
  }

  &:disabled {
    color: $bgdark-primary-grey;
    opacity: 0.5;
  }
}

.spinner__window {
  height: 21px;
  overflow: hidden;
  position: relative;
}

.spinner__value {
  color: $white;
  display: block;
  font-size: 0.75rem;
  line-height: 21px;
  padding: 0 8px;
  text-align: center;
}

.icon-field {
  .search {
    display: block;
    padding: 6px 24px 12px;

    &__label {
      color: #b9c2d3;
      display: inline-block;
      font-size: 14px;
      position: relative;
      width: 100%;
    }

    &__input {
      -webkit-appearance: none;
      background-color: transparent;
      box-sizing: border-box;
      color: #b9c2d3;
      background-color: rgba(177, 186, 204, 0.25);
      border-radius: 3px;
      font-size: 0.875rem;
      height: 36px;
      padding: 12px;
      width: 100%;

      &::placeholder,
      &::-webkit-input-placeholder {
        color: #b9c2d3;
        opacity: 1;
      }
      &:focus {
        outline: 0;
      }
    }
  }

  .icons {
    color: $bgdark-primary-grey;
    font-size: 1.25rem;
    max-height: 232px;
    padding: 0 24px;
    position: relative;

    div:first-child {
      @include styled-scrollbar();
    }

    &__grid {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex: 0 0 20%;
      height: 40px;
      justify-content: center;
      position: relative;
      transition: color 0.3s ease;

      &:hover {
        color: $white;
      }

      &.is-selected {
        color: $white;

        &::after {
          transform: translateX(-50%) translateY(-50%) scale(1);
        }
      }

      &::after {
        border: 1px solid $lp-blue;
        border-radius: 50%;
        content: '';
        height: 36px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0);
        transition: transform 0.3s ease-in-out;
        width: 36px;
      }
    }

    expandable-section {
      width: 100%;

      .expandable-section-header-title {
        display: inline-block;
        font-size: 0.875rem;
      }

      .expandable-section-body {
        display: flex;
        flex-wrap: wrap;
      }

      .slide-toggle {
        border-bottom: 0;
        margin: 0;
        padding: 0;

        &__header {
          border-bottom: 1px solid transparentize($bgdark-primary-grey, 0.6);
          height: 36px;

          &__title {
            display: flex;
          }
        }
      }
    }

    .empty-search {
      color: $bgdark-primary-grey;
      font-size: 0.875rem;
      margin-bottom: 12px;
    }
  }

  .preview {
    border-bottom: 1px solid transparentize($bgdark-primary-grey, 0.5);
    border-top: 1px solid transparentize($bgdark-primary-grey, 0.5);
    color: $bgdark-primary-grey;
    font-size: 1rem;
    max-height: 48px;
    padding: 0 24px;
    text-align: left;

    &__icon {
      display: inline-block;
      font-size: 1.75rem;
      margin-right: 12px;
      text-align: center;
      vertical-align: middle;
      width: 36px;
    }

    &__text {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
      vertical-align: middle;
      white-space: nowrap;
      width: 148px;
      font-size: 15px;
    }
  }
}

.refresh-evergreen {
  align-items: center;
  display: flex;
  height: 0;
  justify-content: space-between;
  overflow: hidden;
  transition: padding 0.3s ease;

  &.is-visible {
    padding: 12px;
  }

  &__date {
    color: $bgdark-primary-grey;
    font-size: 0.75rem;
  }

  .ui-btn-plain {
    font-size: 0.875rem;

    .lp-icon-sync {
      &.is-spinning {
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-name: rotate;
        animation-timing-function: linear;
      }
    }
  }
}
