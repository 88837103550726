%clickable {
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: $lp-blue;
    transition: all 150ms ease;
  }
}

.info-card {
  border-radius: 3px;
  margin: 0 0 4px;
  transition: margin 0.2s ease;

  //when integration has an issue
  &.has-failed {
    .info-card__order,
    .info-card__label,
    .info-card__lock,
    .info-card__toggle {
      opacity: 0.5;
    }
  }

  &__body {
    background-color: $leads-grey-lighter;
    border: 1px solid $leads-grey;
    border-top: 1px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: $bglight-primary-grey;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  &-body {
    overflow: hidden;
    padding: 12px;

    .slide-container {
      height: 80px; // TODO: Achieve smooth transition with dynamic height.
      transition: height 0.2s ease;
    }
  }

  &-body__button {
    float: right;
    opacity: 1;

    > .info-card-button {
      margin: 0;
    }
  }

  &__label {
    color: $leads-grey-darker;
    flex: 1;
    padding: 0 12px;
    font-size: 0.9375rem;
  }

  &-label {
    max-width: 290px;
  }

  &__header {
    background-color: $white;
    border: 1px solid $leads-grey;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    align-items: center;
    color: $leads-grey-darker;
    display: flex;
  }

  &__section {
    align-items: center;
    display: flex;

    &:first-child {
      flex: 1;
    }

    &:last-child {
      flex: 0 0 auto;
    }
  }

  &__order {
    max-width: 0;
    overflow: hidden;
    transition: max-width 200ms ease;

    &.is-visible {
      max-width: 40px;
    }

    &.is-visible + .info-card__label {
      padding: 0;
    }

    .lp-icon {
      font-size: 1.25rem;
      line-height: 1;
    }

    &--up,
    &--down {
      @extend %clickable;

      display: flex;
      padding: 0 6px;
    }

    &--up {
      padding-top: 6px;
    }

    &--down {
      padding-bottom: 6px;
    }
  }

  &__remove {
    @extend %clickable;

    padding: 12px 12px 12px 6px;
  }

  &__delete {
    padding: 12px 6px;
    position: relative;

    &.no-toggle {
      padding: 12px 12px 12px 6px;
    }

    .ui-btn-plain {
      transition: color 0s ease;

      &:hover,
      &:focus {
        .lp-icon {
          color: $lp-red;
        }
      }

      .lp-icon {
        font-size: 1.25rem;
      }
    }
  }

  &__lock {
    color: $bglight-primary-grey;
    padding: 12px 6px;
  }

  &__toggle {
    @extend %clickable;

    padding: 12px 12px 12px 6px;
  }

  &__back {
    @extend %clickable;

    max-width: 0;
    overflow: hidden;
    padding: 12px 0;
    transition: all 200ms ease;

    &.is-visible {
      max-width: 32px;
      padding-left: 12px;
    }

    &.is-visible ~ .info-card__label {
      padding: 0 6px;
    }
  }

  &--add {
    align-items: center;
    border: 1px dashed $bgdark-primary-grey;
    border-radius: 3px;
    color: $bgdark-primary-grey;
    cursor: pointer;
    display: flex;
    font-size: 0.75rem; //12/16
    margin: 5px 6px 0;
    padding: 12px;
    text-decoration: none;
    transition: border-color 0.2s ease, color 0.2s ease;

    .lp-icon {
      font-size: 1.125rem; //18/16
      margin: 0 12px 0 0;
    }

    &:hover,
    &:active,
    &:focus {
      border-color: $white;
      color: $white;
    }
  }

  .lp-icon-error {
    color: $lp-red;
  }
} //end info-card

// collapsed info-cards
.card-closed {
  .info-card {
    margin-left: 6px;
    margin-right: 6px;

    &__header {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      cursor: pointer;
    }
  }

  .info-card-body {
    padding: 0;

    .slide-container {
      height: 0;
    }
  }

  .info-card-body__button {
    opacity: 0;
  }

  .info-card__body {
    opacity: 0;
  }
}

//disabled info-cards
//when integration has failed
.is-disabled {
  .info-card__header {
    opacity: 0.5;
  }
}
