.modal-checkbox {
  color: $bglight-primary-grey;
  display: block;
  margin-top: 12px;
  opacity: 1;
  transition: opacity 0.3s linear;

  &.is-hidden {
    opacity: 0;
  }
}
