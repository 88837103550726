// Text truncate
// Requires inline-block or block for proper styling
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Disable interaction, but don't affect opacity
// like the default "is-disabled" behavior from LEGO.
.is-disabled--no-opacity {
  pointer-events: none;
  cursor: not-allowed;
}

// Hides content visually, but readable by screen readers
.u-a11y-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
