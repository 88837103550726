//** New colors from 2024 redesign **//
$lp-greyLightest: #e4e7ed;
$lp-greyLight: #939393;
$lp-grey: #555555;
$lp-greyDark: #323232;
$lp-greyDarker: #252525;
$lp-greyDarkest: #161819;
$lp-indigo95: rgba(96, 62, 255, 0.95);
$lp-indigo50: rgba(96, 62, 255, 0.5);
$lp-indigo12: rgba(96, 62, 255, 0.12);
$lp-teal: #8fefef;

//** Primary Color Palette **//
$lp-indigo-new: #603eff;

//** Primary Color Palette - Legacy **//
$lp-blue: #0069ff;
$lp-blue-hover: #0055cc;

$base-grey: #f2f4f7;
$base-grey-hover: #c0c2c4;

//** Secondary Color Palette **//
$lp-navy: #00044c;
$lp-yellow: #fed200;
$lp-yellow-hover: #ddaa00;

//** Greyscale **//
$white: #ffffff;
$black: #000000;
$alternate-base-grey: #e6e9ef;

$bglight-primary-grey: #4c515d;
$bglight-secondary-grey: #797f89;
$bgdark-primary-grey: #b1bacc;
$bgdark-secondary-grey: #808090;

//** Alternate Interface Color **//
$lp-purple-dark: #43418c;
$lp-purple-light: #202365;
$lp-light-blue-alt: #a4c8fd;
$lp-light-blue-alt-hover: #d1e3fe;

//** Tertiary Colors **//
$lp-green: #32c88c;
$lp-green-hover: #259468;
$lp-red: #ed6347;
$lp-red-hover: #bf0711;
$lp-indigo: #3c288c;
$lp-indigo-hover: #271a59;

//** Socials **//
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$linkedin: #007bb6;
$pinterest: #e60023;
$instagram: #e1306c;
$youtube: #ff0000;

//** Uncategorized **//
$toast-red: #ffe0dd;

//** Leftover Colors **//
//Delete as they become obsolete in the Rebrand

$greyscale-03: #333333;
$greyscale-06: #808080;
$greyscale-07: #999999;
$greyscale-08: #cccccc;
$greyscale-09: #dddddd;

//Mainly used in section-base.scss
$lp-blue-shade: #c1e7fb; //toast color
$lp-light-blue: #b6e3f2; //widget-options__bar--lightblue???
$lp-lighter-blue: #f2fafe;
$lp-light-grey: #f5f5f5;
$lp-light-grey-blue: #83969b;
$lp-light-grey-blue-hover: #30393a;

// LE&DS Theme
$leads-blue-darker: #00044c;
$leads-blue-dark: #00419f;
$leads-blue: #0069ff;
$leads-blue-light: #4692ff;
$leads-blue-lighter: #deecfd;

$leads-green: #47c1bf;
$leads-green-light: #b7ecec;
$leads-green-darker: #003135;

$leads-grey: #b1bacc;
$leads-grey-accent: #6a7da0;
$leads-grey-light: #e4e7ed;
$leads-grey-lighter: #f2f4f7;
$leads-grey-dark: #797f89;
$leads-grey-darker: #4c515d;

$leads-red: #ed6347;
$leads-red-accent: #ff0033; // NOTE: Not in main LE&Ds theme
$leads-red-dark: #bf0711;
$leads-red-darker: #330101;

//LEGO
$color-alt-gray: #b1bacc;

// Progress
$progress-1: #93143e;
$progress-2: #ef7b8c;
$progress-3: #47c1bf;
$progress-4: #00848e;

// Hover Toolbars
$lp-outline-toolbar: $lp-indigo-new;
$lp-outline-toolbar-hover: $lp-indigo-hover;
$lp-outline-toolbar-attention: #47c0bf;
