.lp-video-react--x-small,
.lp-video-react--small,
.lp-video-react--medium,
.lp-video-react--large,
.lp-video-react--huge {
  .lp-video-react__placeholder {
    padding: 12px;

    &:before {
      border-radius: 6px;
    }
  }
}

.has-content {
  .lp-video-react__placeholder {
    color: $bglight-primary-grey;

    &:before {
      border: none;
    }
  }
}

.has-content.lp-video-react--x-small,
.has-content.lp-video-react--small,
.has-content.lp-video-react--medium,
.has-content.lp-video-react--large,
.has-content.lp-video-react--huge {
  .lp-video-react__placeholder {
    justify-content: space-around;
  }
}

.lp-video-react__placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  align-items: center;

  padding: 0;
  font-size: 2rem;

  // Placeholder base styles
  display: flex;
  flex-direction: column;
  line-height: 1;
  color: $lp-indigo50;
  background-color: #fff;
  text-align: center;
  transition: color 0.3s ease;
  justify-content: center;
  border-radius: 6px;

  // Placeholder border
  &:before {
    content: '';
    border-radius: 6px;
    border: 1px dashed $lp-indigo-new;
    position: absolute;
    top: 12px;
    left: 12px;
    bottom: 12px;
    right: 12px;
  }

  &:hover {
    &:before {
      border-style: solid;
    }
  }
}

// Video placeholder icon
.lp-video-react__placeholder__icon {
  margin: 0;
  padding: 0;
  font-size: 9px;
  line-height: 1;
  position: relative;

  &:after {
    content: '\e281';
    position: absolute;
    top: -2px;
    right: -3px;
    font-family: 'LeadPages-Icons';
    font-size: 7px;
    color: $lp-indigo50;
    transform: scale(1);
    opacity: 0;
  }
}

.has-content {
  .lp-video-react__placeholder__icon:after {
    opacity: 1;
  }
}

.lp-video-react--teeny {
  .lp-video-react__placeholder__icon {
    font-size: 24px;

    &:after {
      top: -2px;
      right: -3px;
      font-size: 9px;
    }
  }
}

.lp-video-react--tiny {
  .lp-video-react__placeholder__icon {
    font-size: 42px;

    &:after {
      top: -5px;
      right: -8px;
      font-size: 18px;
    }
  }
}

.lp-video-react--x-small {
  .lp-video-react__placeholder__icon {
    font-size: 3.75rem;

    &:after {
      top: -2px;
      right: -6px;
      font-size: 1.25rem;
    }
  }
}

.lp-video-react--small {
  .lp-video-react__placeholder__icon {
    font-size: 7rem;

    &:after {
      top: -1px;
      right: -10px;
      font-size: 2.25rem;
    }
  }
}

.lp-video-react--medium {
  .lp-video-react__placeholder__icon {
    font-size: 10rem;

    &:after {
      top: 3px;
      right: -12px;
      font-size: 3rem;
    }
  }
}

.lp-video-react--large {
  .lp-video-react__placeholder__icon {
    font-size: 14rem;

    &:after {
      top: 10px;
      right: -12px;
      font-size: 3.8rem;
    }
  }
}

.lp-video-react--huge {
  .lp-video-react__placeholder__icon {
    font-size: 18rem;

    &:after {
      top: 12px;
      right: -18px;
      font-size: 5rem;
    }
  }
}

// Status Message
.lp-video-react__placeholder__status-msg {
  display: none;
  font-size: 0.75rem;
  justify-content: center;
  color: $bglight-primary-grey;
  line-height: 1.5;
  position: relative;
  bottom: 12%;
}

.lp-video-react--x-small,
.lp-video-react--small,
.lp-video-react--medium,
.lp-video-react--large,
.lp-video-react--huge {
  .lp-video-react__placeholder__status-msg {
    display: block;
    margin: 6px 0 0;
  }
}

.lp-video-react--x-small {
  .lp-video-react__placeholder__status-msg {
    font-size: 0.875rem;
  }
}

.lp-video-react--small {
  .lp-video-react__placeholder__status-msg {
    font-size: 1.125rem;
  }
}

.lp-video-react--medium {
  .lp-video-react__placeholder__status-msg {
    font-size: 1.25rem;
  }
}

.lp-video-react--large {
  .lp-video-react__placeholder__status-msg {
    font-size: 1.375rem;
  }
}

.lp-video-react--huge {
  .lp-video-react__placeholder__status-msg {
    font-size: 1.5rem;
  }
}
