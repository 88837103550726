#the-editor {
  display: flex;
  width: 100%;
  align-items: flex-start;
  position: relative;
}

#comments-area {
  flex-grow: 1;
  position: relative;
}

.comment-mode-active #comments-area-border {
  position: sticky;
  height: 4px;
  z-index: 1000;
  pointer-events: none;
  top: 0;
}

.comment-mode-active #comments-area-border::before {
  content: '';
  position: sticky;
  border: 4px solid #927fef;
  width: 100%;
  height: calc(100vh - 72px);
  display: block;
  left: 0;
  right: 0;
}
