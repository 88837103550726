$max-link-height: 54px;
$action-item-spacing: 15px;
$smart-link-border-color: #474c80;

.smart-linking {
  transition: height 0.3s ease-out;

  .leadpages-field,
  .anchor-links-field,
  .page-list-select-field {
    padding: 12px 18px;
  }

  .ui-search {
    box-sizing: border-box;
  }

  .lp-icon-left_angle {
    color: $bgdark-secondary-grey;
    margin-right: -5px;

    &:hover {
      color: $white;
      cursor: pointer;
    }
  }

  .link-types-list {
    color: $bgdark-primary-grey;
    margin: 0;
    text-align: left;

    &__item {
      align-items: center;
      border-top: 1px solid transparentize($bgdark-primary-grey, 0.6);
      cursor: pointer;
      display: flex;
      font-size: 15px;
      padding: 12px 18px;
      transition: color 0.2s ease;

      &:hover {
        color: $white;
      }
    }
  }

  .links-list {
    margin: 0;

    &__no-items {
      color: $leads-grey;
      padding-top: 12px;
    }

    &__item {
      align-items: center;
      display: flex;
      height: 34px;
      justify-content: space-between;
    }

    .item-details {
      align-items: center;
      color: $leads-grey;
      display: flex;
      flex: 1 1 340px;
      overflow: hidden;
      text-align: left;
      transition: color 0.3s ease;

      &__name {
        flex: 1 0 70px;
        font-size: 15px;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          color: $white;
          cursor: pointer;
        }
      }

      &__url {
        color: $lp-light-blue-alt;
        cursor: pointer;
        font-size: calculate-rem(14px);
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
          color: $lp-light-blue-alt-hover;
        }
      }
    }
  }

  .lp-icon-unlink {
    border: 1px solid $lp-red;
    border-radius: 9999px;
    color: $lp-red;
  }

  .external-link {
    align-items: flex-start;
    border-bottom: 1px solid $smart-link-border-color;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    &__calendly {
      padding-bottom: 0 !important;
    }

    &__label {
      font-weight: 700;
      padding-bottom: 4px;
      width: auto;
    }

    &__input-wrapper {
      padding: 0 18px 12px;
      text-align: left;
    }
  }

  .link-option-title {
    align-items: center;
    color: $leads-grey;
    display: flex;
    justify-content: space-between;
    padding: 12px 18px;
    width: 100%;

    &--leadpages,
    &--opt-in-action {
      padding: 0 0 12px;
    }

    .lp-icon {
      flex: 0 1 20px;

      &:hover {
        color: $white;
        cursor: pointer;
      }
    }

    &__text {
      flex: 1 0 auto;
      font-size: 17px;
      text-align: center;
    }
  }

  .link-option-footer {
    text-align: center;
    padding: 12px 0 0;
    width: 100%;

    &__link {
      color: rgb(164, 200, 253);
      cursor: pointer;
      font-family: Akkurat;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 24px;
      text-decoration: none;
      transition: all 0.25s ease 0s;
      white-space: nowrap;

      &:hover {
        color: $white;
      }
    }
  }

  .chosen-link {
    border-top: 1px solid $smart-link-border-color;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    &__item {
      align-items: center;
      display: flex;
      flex-grow: 1;
      font-size: 15px;
      justify-content: flex-start;
      padding: 0 18px 18px;
      width: 100%;
    }

    &__ellipsis {
      flex: 1 0 20px;
      height: $max-link-height;
      line-height: 25px;
      overflow: hidden;
      text-align: left;

      &:before {
        content: '';
        float: left;
        height: $max-link-height;
        width: 5px;
      }

      > *:first-child {
        float: right;
        margin-left: -5px;
        width: 100%;
      }

      &:after {
        background: linear-gradient(to right, rgba($lp-navy, 0), $lp-navy 50%, $lp-navy);
        background-size: 100% 100%;
        box-sizing: content-box;
        content: '\02026';
        float: right;
        left: 100%;
        margin-left: -3em;
        padding-right: 5px;
        position: relative;
        text-align: right;
        top: -31px;
        width: 3em;
      }
    }

    &__item-name {
      color: $white;
      flex: 1 0 1px;
      font-size: 15px;
      line-height: inherit;
      min-width: 0;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__item-action {
      color: $lp-light-blue-alt;
      cursor: pointer;
      flex: 0 0 20px;
      font-size: calculate-rem(14px);
      font-weight: 700;
      margin-left: $action-item-spacing;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        color: $lp-light-blue-alt-hover;
      }
    }

    &__section {
      &--target-info {
        padding: 0 18px 18px;
        text-align: left;

        .ui-checkbox {
          width: 100%;
          margin-bottom: 15px;

          &__conversion-click {
            ::before {
              margin-top: -6px;
            }

            &:last-of-type {
              margin-bottom: 0px;
            }
          }
        }

        .ui-checkbox__link {
          font-size: 15px;
          // Align to label above.
          margin-left: 22px;
        }

        a {
          font-size: 14px;
          color: $lp-light-blue-alt;
          &:hover {
            color: $lp-light-blue-alt-hover;
          }
        }
      }
    }

    &__footer {
      font-size: 14px;
      padding-bottom: 8px;
      padding-top: 20px;
      width: 100%;
    }

    .chosen-link__footer {
      border-top: 1px solid $smart-link-border-color;
      text-align: center;
    }
  }

  .link-options {
    .link-summary {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .link-details {
        text-align: left;
      }

      .ui-btn--circle {
        background-color: $lp-red;
        border-color: transparent;
        color: $white;
        transition: color $transition-medium, background $transition-medium;

        &:hover {
          background-color: $lp-red-hover;
          color: $white;
        }
      }
    }
  }
}

// BUILDER WIDGET MENU TRUMPING
.btn__widget-menu {
  .leadpages-field,
  .anchor-links-field {
    .ui-search {
      border-radius: 3px;

      &:placeholder {
        color: $leads-grey-accent;
      }
    }

    .ui-search-btn {
      color: $leads-grey-accent;
    }
  }

  .smart-linking {
    .links-list__item {
      .item-details {
        flex-basis: 180px;
      }
    }
  }
}

.widget-help {
  color: $bgdark-primary-grey;
  cursor: pointer;
  display: block;
  font-size: 0.8rem;
  margin-bottom: 18px;

  &__link {
    color: $lp-blue;

    &:visited {
      color: $lp-blue;
    }
  }
}
