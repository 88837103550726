// all the things section

section {
  &.is-hovered {
    position: relative;
  }
}

.section__clickshield {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: layer(section-shield);
}

.section__settings-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 0 0 7px;
  width: 75px;

  #pre__optimized__background {
    background: linear-gradient(to right, #65e0fe 0%, #7059ff 54.63%, #fe51c8 138.8%);
  }
  #optimized__background__1 {
    background: linear-gradient(to right, #65e0fe, #6b9dff);
  }
  #optimized__background__2 {
    background: linear-gradient(to right, #6b9dff, #c954dd);
  }
  #optimized__background__3 {
    background: linear-gradient(to right, #c954dd, #ec52cf);
  }

  .section__settings-item {
    background-color: $lp-outline-toolbar;
    display: flex;
    justify-content: center;
    transition: background-color 0.3s ease;

    @keyframes pre__optimized__animation {
      0% { background : linear-gradient(to right, #65e0fe 0%, #7059ff 54.63%, #fe51c8 138.8%); }
      16.666% { background : linear-gradient(to right, #65E0FE 12.21%, #7059FF 73.96%, #BB55E2 123.94%); }
      33.333% { background : linear-gradient(to right, #BB55E2 -27.58%, #65E0FE 44.11%, #7059FF 95.97%); }
      50% { background : linear-gradient(to right, #BB55E2 -6.45%, #65E0FE 65.11%, #7059FF 122.36%); }
      66.666% { background : linear-gradient(to right, #BB55E2 -5.56%, #B75AE3 27.39%, #65E0FE 90.39%, #7059FF 143.33%); }
      83.333% { background : linear-gradient(to right, #7059FF -4.24%, #BB55E2 55.7%, #65E0FE 127.26%); }
      100% { background: linear-gradient(to right, #65E0FE 0%, #7059FF 49.8%, #BB55E2 100%); }
    }

    &:hover {
      background-color: $lp-outline-toolbar-hover;
      #pre__optimized__background {
        animation: pre__optimized__animation 500ms;
      }
      #optimized__background__1 {
        background: linear-gradient(to right, #65dffea4, #6b9cffa5);
      }
      #optimized__background__2 {
        background: linear-gradient(to right, #6b9cffa1, #c954dd9a);
      }
      #optimized__background__3 {
        background: linear-gradient(to right, #c954dda3, #ec52d0a5);
      }
    }

    button.section__settings-link,
    a.section__settings-link {
      cursor: pointer;
      color: $white;

      .lp-icon {
        color: $white;
        font-size: 1.2rem;
        padding: 0 5px;
      }
    }
  }

  .hide-background {
    background-color: transparent !important; /* Use !important to override the default background-color */
  }

  .section__settings-item--disabled {
    visibility: hidden;

    button.section__settings-link,
    a.section__settings-link {
      cursor: not-allowed;
    }
  }

  &--text {
    margin: 0 0 0 1px;
    width: auto;

    .section__settings-item {
      text-transform: uppercase;
      height: 27px;

      button.section__settings-link,
      a.section__settings-link {
        font-size: 0.875rem;
        font-weight: $font-weight-bold;
        line-height: 27px;
        padding: 0 8px;
        &.more {
          padding: 0;
        }
      }
    }
  }
}

.box-outline {
  &.widget-section {
    box-shadow: inset $lp-outline-toolbar 0 0 0 2px !important;
  }

  &.layout,
  &.column {
    box-shadow: none !important;
    position: relative;
    &::before {
      box-shadow: $lp-outline-toolbar 0 0 0 2px;
      content: '';
      height: 100%;
      width: 100%;
      display: block;
      position: absolute;
      z-index: 1;
    }
  }

  .widget-section__title {
    visibility: visible;
  }
}

.section-wrapper {
  .new-section {
    display: none;
  }

  &:hover {
    position: relative;

    .new-section {
      opacity: 0;
      bottom: -12px;
      display: block;
      height: 26px;
      left: 0;
      position: absolute;
      right: 0;
      transition: opacity ease-in 150ms;

      &__btn {
        box-shadow: $shadow-default;
        display: none;
        line-height: 16px;
        &::before {
          line-height: 16px;
        }
      }

      &:hover {
        opacity: 1;
        z-index: layer(new-section-hover);

        .new-section__border {
          border-bottom: 2px dashed $lp-blue;
          margin-top: 12px;
        }

        .new-section__btn {
          align-items: center;
          box-shadow: $shadow-default;
          cursor: pointer;
          display: flex;
          justify-content: center;
          margin: 0 auto;
          top: -13px;
          position: relative;
          transition: all $transition-medium;

          &:hover {
            box-shadow: $shadow-default-02;
          }
        }
      }
    }
  }
}

.pane-is-active,
.pane-section--current {
  display: block;
}
