@import '../styles/_settings.colors.scss';

$velt-text-grey: #91919c;
$velt-sidebar-width: 400px;
$velt-lilac: #927fef;

$builder-sidenav-width: 72px;

#comments-sidebar-mount {
  .velt-sidebar-filter {
    gap: 8px;
  }

  .velt-sidebar-filters {
    width: 400px !important;
    background: #141416;
    padding: 16px 16px 0 16px;
    border: 1px solid #252525;
    position: fixed;
    right: 0;
    bottom: 0;
  }

  .velt-sidebar-container {
    input::placeholder,
    textarea::placeholder,
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: $velt-text-grey;
      opacity: 1;
    }

    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: $velt-text-grey;
      opacity: 1;
    }
  }

  .velt-sidebar--no-comments-message-container * {
    color: $velt-text-grey;
  }
}

.velt-thread-card--container {
  margin-bottom: 16px;
}

// Targets a custom velt element, not a class
velt-comment-dialog-wireframe {
  width: 100%;
}

app-comment-sidebar-list::-webkit-scrollbar {
  display: none;
}

// Hides the number beside the page name in the sidebar
.velt-sidebar-count {
  display: none !important;
}

// The border and card around the comment area
.velt-persistent-comment-mode-frame {
  width: calc(100% - $velt-sidebar-width) !important;
  border: none !important;

  .velt-persistent-comment-mode-block-content {
    background-color: $velt-lilac !important;
  }
}

// Prevent a velt .dialog from clashing with builder styles
.velt-comment-dialog.dialog {
  opacity: 1 !important;
}

.velt-comment-dialog-overlay-panel {
  * {
    opacity: 1 !important;
  }
}

// Prevent velt's comment modal from appearing over the leadbar
.bar-editor {
  .velt-persistent-comment-mode-block-content {
    display: none !important;
  }
}

// Fix editor positioning on /#/leadbox comment mode
.comment-mode-active #pop-up-mount .dialog.js-active {
  width: calc(100% - 400px);
}

// Fix editor width when editing a popup in comment mode
.leadbox-dialog-comment-mode-on {
  width: calc(100% - $velt-sidebar-width) !important;
}

// Prevents button text from being editable, via the "contenteditable" attribute, while in comment mode
.comment-mode-active [data-widget-type='LpButtonReact'] .lp-button-react {
  pointer-events: none;
}

// Fix bug where images have an overlay in comment mode
.comment-mode-active {
  .lp-image-overlay-wrapper:hover {
    img {
      filter: none;
    }

    .lp-image-overlay-caption {
      display: none;
    }
  }
}

// Indicate to users that the side nav buttons can't be clicked while in comment mode
.comment-mode-active {
  side-nav {
    button {
      cursor: not-allowed;
    }
    button:hover {
      background: transparent;
    }
  }
}

.persistent-comment-mode-block {
  * {
    color: black !important;
    fill: black !important;
  }

  .velt-persistent-comment-mode-divider {
    background: black !important;
  }
}

.v-notifications-panel-container {
  background: $lp-greyDarkest !important;
  color: $white;
  position: fixed;
  bottom: 12px;
  left: calc($builder-sidenav-width + 12px);
  min-height: 400px;

  .velt-notification-panel--title span {
    color: $white !important;
  }

  .velt-notifications-panel--all-read-container {
    height: 275px !important;
  }

  // Remove hand cursor on notifications since we don't support onClick
  .velt-notifications-panel--list-item:hover {
    cursor: default;
  }
}
