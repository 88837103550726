// ██████╗ ██╗   ██╗██╗██╗     ██████╗ ███████╗██████╗
// ██╔══██╗██║   ██║██║██║     ██╔══██╗██╔════╝██╔══██╗
// ██████╔╝██║   ██║██║██║     ██║  ██║█████╗  ██████╔╝
// ██╔══██╗██║   ██║██║██║     ██║  ██║██╔══╝  ██╔══██╗
// ██████╔╝╚██████╔╝██║███████╗██████╔╝███████╗██║  ██║
// ╚═════╝  ╚═════╝ ╚═╝╚══════╝╚═════╝ ╚══════╝╚═╝  ╚═╝

// focusing of elements for builder
div[contenteditable],
h1,
h2,
h3,
p,
a {
  &:focus {
    outline: 0;
  }
}

.widget {
  &-column {
    &__image-and-text {
      &--image {
        z-index: layer(widget-column-image-and-text-image);
      }
    }
  }
  span:focus {
    outline: 0;
  }
}

column {
  position: relative;
}

.detached-drag-handle {
  cursor: grab;

  .lp-icon {
    background-color: $lp-blue;
    color: $white;
  }
}

// layouts and placeholders code for blank spaces
.layout-is-active {
  .container {
    position: relative;
    z-index: layer(layout-active-container);
  }
}

.layout-is-active:hover::after {
  border: 2px solid $lp-blue;
  border-radius: 3px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: layer(layout-active-container-after);
}

// placeholder code
.area-placeholder {
  // container to have min-height when placeholder
  // is added from mouse event
  min-height: 38px;
  transition: min-height 0.3s ease;

  .container {
    min-height: 38px;
    transition: min-height 0.3s ease;
  }

  &::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }

  &--section {
    position: relative;

    &::after {
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: layer(section-placeholder);
    }

    &:hover {
      &::after {
        border: 2px solid $lp-blue;
      }
    }

    &.area-placeholder--ghost {
      &::before {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }
    }
  }

  &--column {
    &:hover {
      &::after {
        border: 1px solid #838383;
        z-index: layer(column-placeholder);
      }
    }
  }

  &--ghost {
    &::before {
      bottom: 12px;
      content: '';
      height: auto;
      left: 0;
      max-width: none;
      right: 0;
      top: 12px;
      width: auto;
    }

    &::after {
      background-color: rgba(0, 105, 255, 0.6);
      background-image: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 12px,
        rgba(0, 0, 0, 0.1) 0,
        rgba(0, 0, 0, 0.1) 24px
      );
      border: 1px dashed $lp-blue;
    }
  }

  &--invalid {
    &::before {
      color: $lp-red;
      content: '\e017';
      font-family: 'LeadPages-Icons';
      font-size: 1.8rem;
      height: 24px;
      line-height: 1;
      margin: auto;
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
      width: auto;
    }

    &::after {
      border: 1px solid $lp-red;
    }
  }
}

.btn-container {
  position: relative;
  z-index: layer(button-container);
}

.shield-is-applied {
  position: relative;
  z-index: layer(shield-is-applied);
}

.shield-is-applied.widget-section,
.shield-is-applied.widget-widget,
.shield-is-applied > .widget-widget {
  box-shadow: 0 0 0 9999px rgba(177, 185, 205, 0.5);
  position: relative;
  z-index: layer(shield-is-applied-widget);
}

.shield-is-applied::after {
  content: '';
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transform: translateZ(0);
}

template-editor,
preview-pane {
  color: $black;
  font-family: Roboto, sans-serif;

  // This seems pretty intense to target all li's with Lego.  It can be discussed
  // but we handle a lot of the spacing in specific lists depending on their context
  ul > li,
  ol > li {
    font-size: inherit;
    margin: 0;
  }
}

.widget[data-widget-type='LpInputReact'] {
  padding-bottom: 0;
}

.widget[data-widget-type='LpProductSummaryReact'] {
  padding-bottom: 0;
  padding-top: 0;
}

[data-widget-type='lp-form-react'] .widget[data-widget-type='LpButtonReact'],
[data-widget-type='lp-checkout-react'] .widget[data-widget-type='LpButtonReact'] {
  padding-top: 12px;
}
