.remove-md {
  cursor: pointer;
  position: relative;
}

.remove-modal {
  display: flex;
  font-size: 14px;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  width: 312px;
  z-index: layer(remove-modal);

  &--top {
    bottom: calc(100% + 6px);

    &::before {
      top: 100%;
    }

    &::after {
      border-color: $lp-navy transparent transparent transparent;
      border-width: 6px;
      top: 100%;
    }
  }

  &--bottom {
    top: calc(100% + 6px);

    &::before {
      bottom: 100%;
    }

    &::after {
      border-color: transparent transparent $lp-navy transparent;
      border-width: 0 6px 6px;
      bottom: 100%;
    }
  }

  &::before {
    background-color: transparent;
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: layer(remove-modal-before);
  }

  &::after {
    border-style: solid;
    content: '';
    height: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 0;
  }

  &__prompt {
    border-radius: 24px 0 0 24px;
    flex: 2 0 auto;
    text-align: left;

    span {
      display: inline-block;
      padding: 12px 12px 12px 24px;
    }
  }

  &__cancel {
    flex: 0 0 72px;
    white-space: nowrap;

    span {
      display: inline-block;
      padding: 12px;
    }

    &:hover {
      color: $white;
    }
  }

  &__prompt,
  &__cancel,
  &__delete {
    display: flex;
    align-items: center;
  }

  &__prompt,
  &__cancel {
    background-color: $lp-navy;
    color: $bgdark-primary-grey;
    transition: color 0.3s;
  }

  &__cancel,
  &__delete {
    cursor: pointer;
    transition: background-color 0.3s;
  }

  &__delete {
    background-color: $leads-red-accent;
    border-radius: 0 24px 24px 0;
    color: $white;
    flex: 0 0 72px;
    white-space: nowrap;

    span {
      display: inline-block;
      padding: 12px;
    }

    &:hover {
      background-color: $leads-red-dark;
    }
  }

  &__close {
    background-color: $white;
    border-radius: 24px 0 0 24px;
    color: $black;
    padding: 12px 12px 12px 24px;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      background-color: $greyscale-08;
    }
  }

  &__save {
    background-color: $lp-green;
    border-radius: 0 24px 24px 0;
    color: $white;
    padding: 12px 24px 12px 12px;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      background-color: tint($lp-green, 20%);
    }
  }
}

// POSITIONING //
.remove-modal {
  &--left,
  &--right {
    border-radius: 24px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.8);

    &::before {
      height: 12px;
    }
  }

  &--left {
    &::after {
      left: 34px;
      right: auto;
    }
  }

  &--right {
    &::after {
      left: auto;
      right: 34px;
    }
  }
}
// COLORING FOR OFFSETS //
.remove-modal--top.remove-modal--right {
  &::after {
    border-top-color: $lp-red;
  }
}

.remove-modal--bottom.remove-modal--right {
  &::after {
    border-bottom-color: $lp-red;
  }
}
