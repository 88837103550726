integration-select {
  width: 100%;

  .flex-wrap-row {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .integration {
    background: none;
    border: 0;
    cursor: pointer;
    flex: 0 0 176px;
    padding: 15px 0;
    text-align: center;

    &:hover,
    &:active,
    &:focus {
      outline: none;

      .integration__logo {
        box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.4);
        transform: translate(0, -2%) scale(1.03);

        &::after {
          box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.4);
        }
      }
    }

    &.is-connected {
      .integration__logo {
        &::before {
          content: '';
        }

        &::after {
          color: $lp-blue;
          content: '\e281';
          font-family: 'LeadPages-Icons';
        }
      }
    }

    &.has-error {
      .integration__logo {
        &::before {
          content: '';
        }

        &::after {
          color: $lp-red;
          content: '\e000';
          font-family: 'LeadPages-Icons';
        }
      }
    }

    &.is-pending {
      .integration__logo {
        &::before {
          content: '';
        }

        &::after {
          color: $lp-yellow;
          content: '\e900';
          font-family: 'LeadPages-Icons';
        }
      }
    }

    &__logo {
      margin: 0 auto;
      position: relative;
      transition: all 0.3s;

      img {
        max-height: 58px;
        max-width: 58px;
      }

      &::before,
      &::after {
        border-radius: 50%;
        bottom: 0;
        margin: -2px 0;
        position: absolute;
        right: 0;
        transition: inherit;
      }

      &::before {
        background: $white;
        height: 22px;
        right: 0;
        width: 22px;
        z-index: layer(integration-select-before);
      }

      &::after {
        font-size: 1.75rem;
        line-height: 0.8;
        width: 24px; // hacky fix
        z-index: layer(integration-select-after);
      }
    }

    &__name {
      color: $bglight-secondary-grey;
      font-size: 1rem;
      font-weight: 300;
      padding: 20px 0 8px;
    }

    &__status {
      color: $greyscale-07;
      font-size: 0.625rem;
      line-height: 1.5;
    }
  }
}
