.layout-pane {
  color: $bgdark-primary-grey;

  &__structure,
  &__properties {
    left: auto;
    right: 0;
    width: 408px;
  }

  &__structure {
    padding: 0 10px 0 18px;
  }

  &__properties {
    padding: 0 10px 0 24px;

    .layout-pane__header {
      h4 {
        cursor: pointer;
        max-width: 360px;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: color 0.2s ease;
        white-space: nowrap;

        i.lp-icon {
          margin-bottom: 3px;
        }

        &:hover {
          color: $white;
        }
      }
    }
  }

  &__header {
    align-items: center;
    display: flex;
    height: $lp-header-height;
    justify-content: space-between;
    margin: 0;
    padding-right: 14px;

    .layout-pane__structure & {
      padding-left: 6px;
    }

    h4 {
      font-size: 1.25rem;
      margin: 0;
      padding: 0;
    }
  }

  &__body {
    height: calc(100% - 72px);
    opacity: 1;
    padding: 0 12px 24px 0;
    position: relative;
    transition: all 0.3s linear;
    visibility: visible;
    width: 100%;
    @include styled-scrollbar();

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }

    // Add small negative margin to allow tooltips in layout pane to be visible underneath icons
    .layout-pane__structure & {
      padding-left: 8px;
      margin-left: -4px;
    }
  }

  h5 {
    color: $white;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
    padding-bottom: 16px;
  }

  .section-list {
    font-size: 0.875rem;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid transparentize($bgdark-primary-grey, 0.6);

    &:hover,
    &.editor-highlight {
      .section {
        opacity: 0.6;

        // Layout Panel Highlight
        &.section-hoverHighlight {
          opacity: 1;

          .slide-toggle {
            border-bottom-color: transparentize($bgdark-primary-grey, 0.595);

            .expandable-section-header-title {
              color: $white;
            }
          }
        }

        &:hover {
          opacity: 1;

          & > .slide-toggle {
            border-bottom-color: transparentize($bgdark-primary-grey, 0.595);
          }
        }
      }
    }

    &__btn {
      background: none;
      border: 0;
      color: $bgdark-primary-grey;
      cursor: pointer;
      display: inline-block;
      margin: 0;
      outline: none;
      padding: 18px 0;
      text-align: left;
      vertical-align: middle;
      width: 100%;

      &:hover {
        color: $white;
      }

      &--settings {
        position: relative;

        i {
          font-size: 16px;
        }

        &::after {
          border-radius: 50%;
          content: '\e20f'; // lp-icon-ellipsis
          font-family: 'LeadPages-Icons';
          font-size: 18px;
          height: 24px;
          line-height: 1;
          padding: 1px;
          position: absolute;
          right: 0;
          text-align: center;
          top: 50%;
          transform: translateY(-50%);
          width: 24px;
        }

        &:hover {
          &::after {
            color: $white;
            content: '\e2cb'; // lp-icon-settings
          }
        }
      }

      .lp-icon {
        margin-bottom: 5px;
        margin-right: 6px;
      }

      &.ui-flyout {
        ul {
          top: calc(100% - 18px);
        }
      }
    }

    /* trumps for editable name */
    .slide-toggle__header__title {
      flex: 1 1 auto;
      padding-right: 12px;
    }

    .expandable-section-header-title,
    .column__name {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 20px);
    }

    .action-btn {
      background: none;
      border-radius: 12px;
      color: $bgdark-primary-grey;
      cursor: pointer;
      height: 24px;
      outline: none;
      width: 24px;
      transition: background $transition-medium, color $transition-medium;

      &:not(:first-child) {
        margin-left: 3px;
      }

      &:last-of-type {
        margin-left: 6px;
      }

      &:hover {
        color: $white;
        background: $lp-purple-dark;
      }

      .lp-icon {
        font-size: 18px;
        line-height: 1;
      }

      &--settings {
        border-color: $bgdark-primary-grey;
        color: $bgdark-primary-grey;
      }

      &--duplicate {
        &:hover {
          ~ .action-btn--settings {
            border-color: $bgdark-primary-grey;
            color: $bgdark-primary-grey;
          }
        }

        .lp-icon {
          font-size: 18px;
        }
      }

      &--remove {
        position: relative;

        &:hover {
          background: $lp-red;
          color: $white;
        }
      }
    }
  }

  .section {
    color: $bgdark-primary-grey;
    padding: 0;
    transition: opacity 0.2s ease;

    &:hover {
      color: $white;
    }

    &__name {
      font-size: 1rem;
    }

    .slide-toggle {
      border-top: 1px solid transparentize($bgdark-primary-grey, 0.6);
      border-bottom-color: transparent;
      cursor: pointer;
      margin: 0;
      padding: 18px 0;

      &:not(:last-child) {
        border-bottom: 0;
      }

      &.slide-toggle--is-open {
        padding-bottom: 0;
      }
    }

    .slide-toggle__body {
      cursor: default;
      padding-left: 24px;
    }
  }

  .layout {
    color: $bgdark-primary-grey;
    position: relative;

    &:hover {
      color: $white;
    }

    &:last-child {
      .slide-toggle {
        border-bottom: 0;
      }
    }

    .slide-toggle {
      margin: 0;
      padding: 18px 0 0;

      &.slide-toggle--is-open {
        .slide-toggle__header {
          padding-bottom: 6px;
        }
      }
    }

    .slide-toggle__header {
      border: 0;
      margin-bottom: -1px;
      padding-bottom: 18px;
    }

    .slide-toggle__body {
      padding-left: 18px;
    }

    .section-list__btn {
      padding-top: 6px;
    }
  }

  .column-list {
    margin: 0;
    padding: 0;

    .column {
      align-items: center;
      color: $bgdark-primary-grey;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      height: 48px;
      justify-content: space-between;
      padding: 18px 0;

      &:hover {
        color: $white;
      }
    }
  }

  .slide-toggle__header__actions {
    &.show-default {
      .action-btn {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.show-actions {
      .action-btn {
        opacity: 1;
        visibility: visible;
      }

      .actions-default {
        display: none;
      }
    }
  }

  .advanced-group {
    border-left: 1px solid $lp-blue-hover;
    padding-left: 12px;

    .property-setting:first-child {
      margin-top: 6px;
    }
  }

  .property-setting {
    align-items: center;
    color: $bgdark-primary-grey;
    display: flex;
    font-size: 14px;
    justify-content: flex-start;
    margin-top: 18px;
    min-height: 24px;
    opacity: 1;
    position: relative;
    transition: opacity 0.25s linear;

    &:first-child {
      margin-top: 24px;
    }

    &.is-disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &--half-width {
      display: inline-block;
      width: 49%;
    }

    &--noalign {
      align-items: unset;
    }

    &--space-between {
      justify-content: space-between;
    }

    &__title {
      color: $bgdark-secondary-grey;
      flex: 0 0 auto;
      font-size: 12px;
      line-height: 1;
      margin-right: 6px;
      text-transform: uppercase;

      + .property-setting__list {
        margin-left: 18px;
      }
      &--image {
        margin-right: 18px;
      }
    }

    &__control {
      cursor: pointer;
      margin-left: 6px;
      max-width: 164px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.lp-icon-settings {
        transform: rotate(0deg);
        transition: color 0.2s ease, transform 0.2s ease;

        &:hover,
        &.is-active {
          color: $lp-blue;
        }

        &.is-active {
          transform: rotate(30deg);
        }
      }

      &.lp-icon-unavailable {
        transition: color 0.25s linear;

        &:hover {
          color: $lp-red;
        }
      }
    }

    &__list {
      align-items: center;
      display: flex;
      font-size: 0.875rem;
      justify-content: space-between;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        color: $bgdark-primary-grey;
        cursor: pointer;
        text-align: center;
        transition: all 0.25s ease;

        &::before {
          color: $lp-blue;
          content: '\e206';
          font-family: 'LeadPages-Icons';
          height: 14px;
          margin-right: -14px;
          opacity: 0;
          overflow: hidden;
          transition: all 0.25s ease;
          width: 14px;
        }

        &:first-child {
          text-align: left;

          &.active {
            &::before {
              margin-right: 6px;
            }
          }
        }

        &:last-child {
          text-align: right;
        }

        &:hover {
          color: $white;
        }

        &.is-active {
          color: $white;

          &::before {
            margin: 0 6px 0 0;
            opacity: 1;
          }
        }

        [data-tips] {
          font-size: 0.875rem;

          &::after {
            font-size: 0.75rem;
          }
        }
      }
    }

    .ui-toggle {
      margin-right: 8px;
    }
  }

  .range {
    min-height: 24px;
  }

  .range__title {
    flex-basis: 55px;
    margin-right: 0;
  }

  input[type='range'] {
    margin: 12px 0;
  }

  .range__value {
    flex-basis: 36px;

    .value-ext {
      min-height: 24px;
      padding: 0 5px;
    }
  }

  /* for delete confirmation */
  .delete-container {
    display: inline-block;
    position: relative;

    &:hover {
      ~ .action-btn--settings {
        border-color: $bgdark-primary-grey;
        color: $bgdark-primary-grey;
      }
    }
  }

  .confirmation {
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 0 0 1px $bglight-primary-grey;
    display: flex;
    font-size: 12px;
    height: 24px;
    overflow: hidden;
    position: absolute;
    right: -1px;
    top: -1px;
    transition: all 0.2s ease;
    z-index: layer(layout-pane-confirm);

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }

    &__btn {
      background: $lp-navy;
      height: 100%;
      padding: 4px 9px;

      &--confirm {
        background: $lp-red-hover;
        color: $white;

        &:hover {
          background: $lp-red;
        }
      }

      &--cancel {
        color: $bgdark_secondary_grey;

        &:hover {
          color: $white;
        }
      }
    }
  }

  /* classes used for Dragula */
  .drag-handle {
    color: $bgdark-primary-grey;
    cursor: move; /* fallback */
    cursor: grab;
    display: inline-block;
    margin-bottom: 4px;
    margin-left: -6px;
    width: 20px;

    &:hover {
      color: $white;
    }

    &:active {
      cursor: grabbing;
    }
  }

  .layout-locked {
    color: $bgdark-primary-grey;
    display: inline-block;
    margin-bottom: 4px;
    margin-left: -3px;
    width: 20px;

    &:hover {
      color: $white;
    }
  }

  .locked-tooltip-fixed[data-tips].data-tip-bottom:before {
    left: 33%;
  }

  .locked-tooltip-fixed[data-tips].data-tip-bottom:after {
    width: 300px;
    max-width: 300px;
    white-space: normal;
    transform: translateX(-9%);
    left: 100%;
  }

  .gu-unselectable {
    background: $lp-red;
  }

  .gu-transit {
    background: rgba(255, 255, 255, 0.2);
    color: $white;
    opacity: 1;
  }

  aurelia-image-selector {
    flex: 1;
  }

  .image-selector {
    &__remove-button {
      transform: rotate(90deg);
      transition: color 0.25s linear;
      &:hover {
        color: $lp-red;
      }
      & svg {
        font-size: 1.25rem;
        line-height: 1.4;
      }
    }
  }
}

.gu-mirror {
  .slide-toggle__header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &__title {
      flex: 1 1 auto;
    }
  }

  .drag-handle {
    display: none;
  }

  .action-btn,
  .delete-container {
    display: none;
  }

  .slide-toggle__body {
    display: none;
  }
}

.expandable-section-header-value {
  display: flex;
  flex: 1 1 auto;
  font-size: 12px;
  opacity: 1;
  transition: opacity 0.25s linear, visibility 0.25s linear;
  visibility: visible;

  > span {
    margin-left: 12px;
    max-width: 189px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    white-space: nowrap;
  }

  .slide-toggle--is-animating &,
  .slide-toggle--is-open & {
    opacity: 0;
    visibility: hidden;
  }
}

.input-and-label {
  .ui-label {
    margin-right: 12px;
    width: auto;
  }
}

.ui-input.delayDisplaySeconds {
  margin: 0;
  text-align: right;
  width: 4em;
}
