.page-published-modal {
  font-family: Roboto, sans-serif;
  text-align: center;

  ai-dialog-header {
    background-color: $white;
    border-bottom: 0;

    .dialog__title {
      margin-bottom: 12px;
    }

    .dialog__sub-title {
      color: $bgdark-secondary-grey;
      margin-bottom: 24px;
    }
  }

  ai-dialog-body {
    min-height: 250px;
    text-align: center;

    .published-url {
      margin-bottom: 30px;

      &__link {
        font-size: 16px;

        &--sm {
          display: block;
          font-size: 0.75em;
          margin: 12px auto;
        }
      }
    }

    img {
      margin: 6px 0 18px;
      max-width: 180px;
    }
  }

  ai-dialog-footer {
    .social-share {
      float: left;

      &__label {
        color: $bgdark-primary-grey;
        font-size: 0.8em;
        letter-spacing: 1px;
        margin-right: 12px;
        text-transform: uppercase;
      }

      .ui-btn {
        border-color: $bgdark-primary-grey;
        color: $bgdark-primary-grey;

        &:hover {
          &[data-icon='twitter'] {
            border-color: $twitter;
            color: $twitter;
          }

          &[data-icon='facebook'] {
            border-color: $facebook;
            color: $facebook;
          }

          &[data-icon='google_plus'] {
            border-color: $googleplus;
            color: $googleplus;
          }
        }
      }
    }

    button {
      margin: 0;

      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  }

  // Lego trump
  .ui-input {
    font-size: 1.25rem;
    text-align: center;
  }

  .social-share {
    text-align: right;
  }

  .social-share__btn {
    background: none;
    border: 0;
    color: $bglight-secondary-grey;
    font-size: 18px;
    margin: 0;
    outline: none;
    padding: 0;
    transition: color 0.3s ease-in-out;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &:hover {
      &[data-service='twitter'] {
        color: $twitter;
      }

      &[data-service='facebook'] {
        color: $facebook;
      }

      &[data-service='google-plus'] {
        color: $googleplus;
      }
    }
  }
}
