// COLOR SWATCH
// Used in widget toolbar, color picker, section/column settings
// color picker located in utilities
.color-swatch {
  background-color: $white;
  border: 1px solid $white;
  border-radius: 50%;
  display: block;
  height: 18px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 18px;

  &::before,
  &::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: layer(swatch-background);
  }

  &::before {
    background-color: $white;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA0xpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjhCRURERkUyNzBGMTFFNUFEMThEQjlBNkJENTc0QzUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjhCRURERkQyNzBGMTFFNUFEMThEQjlBNkJENTc0QzUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjMzNmYzN2Q5LTZmNzktMTE3OC04Yjg4LWNiY2I5NGIwMTJhYiIgc3RSZWY6ZG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjMzNmYzN2Q5LTZmNzktMTE3OC04Yjg4LWNiY2I5NGIwMTJhYiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnSmHuIAAABASURBVHjaYqysrPzPgB98bG9vF8CnAGiGAhMDlcCoQSPaIBYgFiSgRpaYRMtIyCZQqgVS9wkZNBprI9oggAADAKIRDZS3kRdjAAAAAElFTkSuQmCC');
    background-position: center center;
  }

  &::after {
    background-color: inherit;
  }
}
