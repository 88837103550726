.widget-sidebar,
.lead-box-widget-sidebar {
  .sidebar-title {
    color: $white;
  }

  & .ps-scrollbar-y-rail {
    right: 8px !important;
  }

  .compose-fields {
    width: 100%;

    .section-header {
      border-bottom: 1px solid transparentize($bgdark-primary-grey, 0.6);
      color: $white;
      font-size: 1rem;
      font-weight: 300;
      margin-bottom: 26px;
    }

    .position-grid {
      display: flex;
      flex-direction: column;
      height: 156px;
      justify-content: space-between;
      margin: 0 auto;
      width: 204px;

      &__row {
        display: flex;
        justify-content: space-between;

        .position-dot {
          border: 1px solid $bgdark-primary-grey;
          border-radius: 9px;
          cursor: pointer;
          height: 18px;
          position: relative;
          width: 18px;

          &::after {
            background: $lp-blue;
            border-color: $lp-blue;
            border-radius: 50%;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transform: scale(0);
            transform-origin: center center;
            transition: transform 0.2s linear;
            width: 100%;
          }

          &:hover {
            &::after {
              transform: scale(0.5);
            }
          }

          &.is-active {
            &::after {
              transform: scale(1.1);
            }

            &:hover {
              &::after {
                transform: scale(1.1);
              }
            }
          }

          &.is-disabled {
            background: $bgdark-primary-grey;

            &::after {
              display: none;
            }

            &:hover {
              cursor: not-allowed;

              &::after {
                display: none;
              }
            }
          }
        }

        .position-text {
          color: $bgdark-primary-grey;
          font-size: 0.75rem;
          text-align: center;
          text-transform: uppercase;
          width: 102px;
        }
      }
    }

    .social-share {
      &__title {
        font-size: 0.9375rem;
        font-weight: 300;
        padding: 18px 6px;
      }

      .platform {
        align-items: center;
        color: $greyscale-03;
        cursor: pointer;
        display: flex;
        padding: 6px 0;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: $lp-blue;
        }

        &__icon {
          font-size: 1.25rem;
          margin-right: 12px;
        }

        &__name {
          font-size: 0.75rem;
        }
      }

      .remove-modal--right {
        width: 140px;
        transform: translateX(28%);
      }

      .remove-modal__prompt {
        display: none;
      }

      .remove-modal__cancel {
        flex: 0 0 75px;
        border-radius: 24px 0 0 24px;

        span {
          padding: 12px 18px;
        }
      }

      .platform-header {
        align-items: center;
        display: flex;
        height: 24px;

        &__icon {
          font-size: 1.25rem;
          margin-right: 12px;
        }

        &__title {
          font-size: 0.875rem;
        }
      }

      .info-card-body--social-platform {
        .slide-container {
          transition: height 300ms ease;
          &.is-like {
            height: 80px;
            &.has-alternate-url {
              height: 140px;
            }

            &.has-show-faces {
              height: 108px;
            }

            &.has-url-and-faces {
              height: 180px;
            }
          }

          &.is-social {
            height: 120px;
            &.has-alternate-url {
              height: 180px;
            }

            &.has-profile-url {
              height: 200px;
            }
          }

          &.is-social--profile-only {
            height: 40px;

            &.has-profile-url {
              height: 120px;
            }
          }
        }
      }

      .edit-platform {
        margin: 0 12px;

        &__options {
          font-size: 0.75rem;

          .lp-sup-radio + label span {
            margin-left: 0;
          }
        }

        .profile__url,
        .alternate-page__url {
          input[type='text'] {
            border: 1px solid $bglight-primary-grey;
            border-radius: 3px;
            padding: 0.5rem;
            width: 100%;
          }

          label {
            color: $lp-red;
            font-size: 0.75rem;
            opacity: 0;
            transition: opacity 300ms ease;
          }

          .lp-icon {
            font-size: 0.75rem;
            margin: 0 12px 0 0;
            transition: margin 300ms ease;
          }

          &.is-invalid {
            input[type='text'] {
              border-color: $lp-red;
            }

            label {
              opacity: 1;
            }

            .lp-icon {
              margin: 12px 12px 12px 0;
            }
          }
        }
      }

      .fa-facebook-f {
        color: $facebook;
      }

      .fa-twitter {
        color: $twitter;
      }

      .fa-google_plus {
        color: $googleplus;
      }

      .fa-linkedin-in {
        color: $linkedin;
      }

      .fa-pinterest {
        color: $pinterest;
      }

      .fa-instagram {
        color: $instagram;
      }

      .fa-youtube {
        color: $youtube;
      }
    }
  }
}
