@import 'components.styled-scrollbar';

// HELP PANE
.help-pane {
  &__pipe {
    background: rgba($bglight-secondary-grey, 0.25);
    display: block;
    margin: 0 6px;
    min-height: 36px;
    width: 1px;
  }

  // HEADER
  &__header {
    align-items: center;
    display: flex;
    padding: 24px 30px;
    position: relative;

    h3 {
      flex: 1;
      font-size: 1.25rem;
      font-weight: 400;
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
  }

  // CONTENTS
  &__content {
    height: calc(100vh - 88px);
    padding: 0 30px 80px;
    position: relative;
    @include styled-scrollbar();
  }

  &__message {
    font-size: 1.25rem;
    left: 30px;
    position: absolute;
    right: 30px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);

    a {
      text-decoration: underline;
    }
  }

  &__kbLink {
    font-size: 0.8em;
    font-style: italic;
  }

  &__loading {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__searchResults {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% - 54px);

    &.is-loading {
      justify-content: flex-end;
    }
  }

  &__items {
    display: flex;
    flex: 1 0 1px;
    flex-direction: column;

    legend {
      border-bottom: 1px solid $bgdark-primary-grey;
      display: block;
      font-size: 0.8em;
      font-weight: bolder;
      line-height: 4rem;
      margin-right: 0.25rem;
      overflow: hidden;
      padding: 0 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__item {
    align-items: center;
    border-bottom: 1px solid $bgdark-primary-grey;
    cursor: pointer;
    display: flex;
    height: 4rem;
    padding: 0 0.5rem;
    white-space: nowrap;
    color: $white;

    &:focus-visible {
      color: $white;
      outline: 2px solid $lp-grey;
    }

    &:active,
    &:visited {
      color: $white;
    }

    &:hover {
      background-color: $alternate-base-grey;
      color: $leads-grey-darker;
    }

    &:last-child {
      border-bottom: 0;
    }

    span {
      display: block;
      flex: 1;
      margin-right: 0.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    i {
      flex: 0;
      font-size: 1.5rem;
    }
  }

  &__pagination {
    padding: 1rem 0;
  }

  &__article {
    background-color: $white;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 88px;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    @include styled-scrollbar();

    &.is-open {
      transform: translateX(0);
      transition-timing-function: ease-out;
    }
  }

  &__articleHeader {
    align-items: center;
    background-color: $lp-navy;
    color: $bgdark-primary-grey;
    cursor: pointer;
    display: flex;
    height: 4rem;
    padding: 0 30px 0 10px;

    i {
      font-size: 1.25rem;
    }

    h4 {
      flex: 1;
      font-size: 1.15rem;
      margin-right: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__articleContents {
    height: calc(100% - 4rem);
    position: relative;
  }

  &__articleBody {
    padding: 2rem;

    a:link,
    a:visited {
      border-bottom: 1px solid $lp-blue;
    }

    p {
      margin-bottom: 1rem;
    }

    img {
      display: block;
      height: auto;
      margin: 1rem auto;
      max-height: 100%;
      max-width: 100%;
      width: auto;
    }

    h1 {
      display: none;
    }

    h2 {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .tip {
      background: $lp-light-blue;
      border: 1px solid $lp-blue;
      border-radius: 3px;
      color: $bglight-primary-grey;
      margin: 1rem 0;
      padding: 0.5rem;
    }

    .warning {
      background: $toast-red;
      border: 1px solid $lp-red;
      border-radius: 3px;
      color: $bglight-primary-grey;
      margin: 1rem 0;
      padding: 0.5rem;
    }

    .info {
      border: 1px solid $bglight-primary-grey;
      border-radius: 3px;
      color: $bglight-primary-grey;
      margin: 1rem 0;
      padding: 0.5rem;
    }

    .steps li {
      margin-bottom: 1rem;

      &:before {
        border: 1px solid $lp-blue;
        border-radius: 50%;
        color: $lp-blue;
        left: -2rem;
        line-height: 1.5rem;
        text-align: center;
        top: -0.25rem;
        width: 1.5rem;
      }
    }

    .media_embed {
      position: relative;
      width: 100%;

      &:before {
        content: '';
        display: inline-block;
        padding-bottom: 56.25%;
      }
    }

    .wistia_embed {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
}
