[draggable] {
  -khtml-user-drag: element; // Required to make elements draggable in old WebKit
  -webkit-user-drag: element; // Required to make elements draggable in old WebKit
  -khtml-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.tile {
  background: transparentize($bgdark-primary-grey, 0.7);
  border-radius: 3px;
  cursor: move;
  display: flex;
  flex-direction: column;
  height: 84px;
  margin: 0 6px 6px 0;
  opacity: 1;
  padding: 0 0 10px;
  text-align: center;
  transition: background-color 0.15s ease, opacity 0.15s ease, transform 0.2s ease;
  width: 117px;

  &:hover {
    background-color: transparentize($bgdark-primary-grey, 0.5);
    transform: scale(1.03);
  }

  &.is-dragging {
    opacity: 0.5;
  }

  &.is-beta,
  &.is-new,
  &.is-pro {
    overflow: hidden;
    position: relative;

    & .tile__icon {
      margin: 6px auto;
    }

    &::before {
      background: rgba(177, 186, 204, 0.3);
      color: $white;
      content: '';
      font-size: 11px;
      font-weight: bold;
      left: 0;
      line-height: 12px;
      // position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
      font-weight: bold;
      letter-spacing: 0.64px;
      line-height: 14px;
    }
  }

  &.is-beta {
    &::before {
      background-color: $leads-green;
      color: #000;
      content: 'BETA';
    }
    &.is-pro {
      &::before {
        content: 'BETA | PRO+';
      }
    }
  }

  &.is-pro {
    &::before {
      content: 'PRO+';
    }
  }

  &.is-new {
    &::before {
      background-color: $leads-green;
      color: #000;
      content: 'NEW';
    }

    &.is-pro {
      &::before {
        content: 'NEW | PRO+';
      }
    }
  }
}

.tile__icon {
  color: $white;
  font-size: 1.75rem;
  margin: 12px auto 6px;
}

.tile__label {
  color: $white;
  font-size: 0.75rem;
  line-height: 1;
}
