// color picker styling
$color-field-width: 132px;

%sp-handle {
  background: transparent;
  border: 1px solid $white;
  border-radius: 50%;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5), inset 0 0 1px 1px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 9px;
  width: 9px;
}

.lp-color-picker {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 252px;
  overflow: hidden;
  padding: 10px 0 0;
  transform: translateZ(0);
  react-color-picker {
    & > div > div {
      width: 210px;
    }
  }
}

.sp-input-container input,
.sp-color,
.sp-hue-slider {
  font: normal 14px 'Roboto', helvetica, sans-serif;
}
// input
.sp-input-container {
  margin: 0 auto;
  width: 132px;
}

.sp-input {
  background: none;
  border: 0;
  color: $greyscale-07;
  margin: 0;
  padding: 4px 5px;
  text-align: center;
  width: 100%;

  &:focus {
    outline: 0;
  }
}

.sp-picker-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: 210px;
}

.sp-global-swatches {
  align-content: space-between;
  display: flex;
  flex: 0 0 18px;
  flex-direction: column;
  height: $color-field-width;
  justify-content: flex-start;
  margin-right: 16px;

  .color-swatch {
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  /* TODO: these will need to pull in the background-color dynamically */
  &--alpha {
    background-color: $lp-blue;
  }

  &--beta {
    background-color: $lp-light-blue;
  }

  &--gamma {
    background-color: $lp-green;
  }

  &--delta {
    background-color: $lp-yellow;
  }

  &--epsilon {
    background-color: $lp-red;
  }
}

.sp-color-field-wrapper {
  flex: 0 0 $color-field-width;
  flex-direction: column;
}

.sp-color-field {
  background-image: linear-gradient(
      to bottom,
      transparent calc(0% + 4.5px),
      $black calc(100% - 4.5px)
    ),
    linear-gradient(to right, $white calc(0% + 4.5px), transparent calc(100% - 4.5px));
  border-radius: 4px;
  height: 0;
  margin-right: 16px;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

/* creates the 'gradient border' for the color field */
.sp-color-field::after {
  background: linear-gradient(to bottom, $greyscale-07, $greyscale-09);
  border-radius: 4px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1.01, 1.01);
  z-index: layer(sp-color-field);
}

.sp-color-field__handle {
  @extend %sp-handle;

  left: 0;
  position: absolute;
  top: 0;
}

.sp-alpha-slider {
  margin: 6px 12px;
}

.sp-hue-slider {
  background-image: linear-gradient(
    to top,
    #ff0000 calc(0% + 4.5px),
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 calc(100% - 4.5px)
  );
  border-radius: 9px;
  flex: 0 0 18px;
  height: $color-field-width;
  margin-left: 16px;
  position: relative;
}

.sp-hue-slider__handle {
  @extend %sp-handle;
}

// Don't allow text selection
.lp-color-picker,
.sp-dragger,
.sp-slider,
.sp-container.sp-dragging .sp-input {
  user-select: none;
}

.sp-preview-inner,
.sp-thumb-inner {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
// end of the color picker
