input[type='radio'].lp-sup-radio {
  display: none;

  + label {
    cursor: pointer;
    transition: color 0.15s ease;

    span {
      background-color: transparent;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      height: 16px;
      margin: 12px 12px 12px 0;
      transition: all 0.3s ease-in-out;
      vertical-align: middle;
      width: 16px;
    }
  }

  &:checked + label span {
    background-color: $lp-blue;
  }

  &--light {
    + label {
      &:hover {
        color: $lp-navy;
      }

      span {
        border: 2px solid $bglight-primary-grey;
        box-shadow: inset 0 0 0 2px $white;
      }
    }

    &:checked + label {
      color: $lp-navy;
    }
  }

  &--dark {
    + label {
      &:hover {
        color: $white;
      }

      span {
        border: 2px solid $white;
        box-shadow: inset 0 0 0 2 $lp-navy;
      }
    }

    &:checked + label {
      color: $white;
    }
  }
}
