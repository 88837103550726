// base widget
.crucible-render {
  .shield-is-applied .widget-row {
    &:first-child .widget {
      z-index: layer(widget-form-inputs);
    }
  }

  .widget-row .widget:hover {
    z-index: layer(crucible-shield-is-applied-hover);
  }
}

.widget-row,
widget {
  display: block;

  .widget {
    position: relative;
    z-index: layer(widget);

    &:hover {
      z-index: layer(widget-hover);
    }

    // grey border for clicked/active
    &--clicked {
      border-radius: 3px;
      z-index: layer(widget-clicked);
    }
  }
}

.widget__link-action-error {
  color: $leads-red-accent;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: $font-weight-bold;
  padding: 4px 0;
}

.site-header {
  .widget-widget {
    &.is-hovered ::after,
    &.is-hovered.show-top-border ::after {
      z-index: layer(image-overlay-trump);
    }
  }

  .widget__top .top-options {
    margin-top: 0;
  }

  @container (max-width: 959px) {
    .lp-image-overlay-wrapper {
      text-align: center;
    }
  }
}

.widget-widget {
  transition: box-shadow 100ms ease;
  position: relative;
  z-index: 1;

  &::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.is-loading {
    &::after {
      border: 1px dashed $leads-blue;
    }
  }

  &.is-invalid {
    &::after {
      border: 1px dashed $leads-red;
    }
  }

  &.is-reauth {
    &::after {
      border: 1px dashed $leads-red;
      z-index: layer(widget-needs-auth);
    }
  }

  &.is-success {
    &::after {
      border: 1px dashed $leads-blue;
      z-index: layer(widget-success);
    }
  }

  &.is-hovered {
    &::after {
      border: 4px solid $lp-outline-toolbar;
      border-top: 0;
    }
  }

  &.is-hovered.show-top-border {
    &::after {
      border-top: 4px solid $lp-outline-toolbar;
    }
  }

  &.is-hovered.hide-bottom-border {
    &::after {
      border-bottom: 0;
    }
  }

  &.has-error {
    &::after {
      border: 4px solid $leads-red-accent;
    }

    &.is-hovered {
      &::after {
        border-color: $leads-red-accent;
      }

      &:not(.show-top-border) {
        &::after {
          border-top: 0;
        }
      }
    }
  }
}

// top/left/right bar for displaying 2 half borders and transparent bg

.widget__top {
  top: 0;

  &::before,
  &::after {
    border-top: 4px solid $lp-outline-toolbar;
    content: '';
    display: block;
    flex: 2 0 auto;
    height: 12px;
  }
}

.widget__bottom {
  bottom: 0;

  &::before,
  &::after {
    border-bottom: 4px solid $lp-outline-toolbar;
    content: '';
    display: block;
    flex: 2 0 auto;
  }
}

.has-error {
  .widget__top,
  .widget__bottom {
    &::before,
    &::after {
      border-color: $leads-red-accent;
    }
  }
}

.site-header {
  .widget-widget.is-hovered.show-top-border::after {
    border-bottom: 0;
  }

  .widget__bottom {
    .bottom-options {
      margin: 0 3px;
      transform: translateY(calc(100% - 16px));
      align-items: center;
      max-width: 165px;

      &--button-wider {
        max-width: 190px;
      }

      flex-wrap: wrap;
      justify-content: center;

      // For row wrapping in widget editing options of the header
      // edit icon should not grow, trash can icon should not grow,
      // but all the rest should.
      span:nth-child(n + 3),
      i:nth-child(n + 3) {
        flex-grow: 1;
      }
    }
  }
}

.widget__bottom,
.widget__top {
  display: flex;
  position: absolute;
  justify-content: center;
  left: 0;
  right: 0;
  z-index: layer(widget-options);

  .top-options {
    margin: -14px 5px 0;
  }

  .bottom-options {
    margin: 0 5px;
    transform: translateY(calc(100% - 14px));
  }

  .bottom-options,
  .top-options {
    display: flex;
    flex: 0 0 auto;
    text-align: center;

    &__button {
      background-color: $lp-outline-toolbar;
      color: $white;
      text-transform: uppercase;
      transition: background-color $transition-medium, border-color $transition-medium,
        box-shadow $transition-medium;

      &.lp-icon {
        font-size: 1.125rem;
        font-weight: $font-weight-normal;
        width: 27px;
        padding: 1px 0;
      }

      &.lp-icon-attention {
        color: rgba(0, 0, 0, 0.6);
        animation: rainbow 3.5s linear;
        animation-iteration-count: infinite;

        @keyframes rainbow {
          100%,
          0% {
            background-color: rgb(255, 0, 0);
          }
          8% {
            background-color: rgb(255, 127, 0);
          }
          16% {
            background-color: rgb(255, 255, 0);
          }
          25% {
            background-color: rgb(127, 255, 0);
          }
          33% {
            background-color: rgb(0, 255, 0);
          }
          41% {
            background-color: rgb(0, 255, 127);
          }
          50% {
            background-color: rgb(0, 255, 255);
          }
          58% {
            background-color: rgb(0, 127, 255);
          }
          66% {
            background-color: rgb(0, 0, 255);
          }
          75% {
            background-color: rgb(127, 0, 255);
          }
          83% {
            background-color: rgb(255, 0, 255);
          }
          91% {
            background-color: rgb(255, 0, 127);
          }
        }
      }

      &:hover,
      &.is-hovered {
        background-color: $lp-outline-toolbar-hover;
        box-shadow: $shadow-default-02;
        cursor: pointer;
      }

      &-delete:hover,
      &-delete.is-active {
        background-color: $leads-red-dark;
        border-color: $leads-red-dark;
      }

      &.has-error {
        background-color: $leads-red-accent;

        &:hover,
        &.is-hovered {
          background-color: $leads-red-dark;
        }
      }
    }

    &__text-button {
      font-size: 0.875rem;
      font-weight: $font-weight-bold;
      line-height: 27px;
      padding: 0 8px;
    }
  }
}

compose[class*='flex__'] {
  position: relative;
}
