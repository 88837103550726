fields-editor {
  .info-card-label {
    max-width: 235px;
  }

  .card-closed {
    .info-card-label {
      max-width: 285px;
    }
  }

  .info-card-body {
    .slide-container__slide {
      height: auto;
    }
  }
}

.fields-editor {
  &__supported-fields-list {
    height: 0 !important;
  }
}

.section-body {
  margin: 0px 18px 24px;
  border-bottom: 1px solid rgba(177, 186, 204, 0.4);
  padding-bottom: 24px;
}

.property-setting {
  align-items: center;
  color: $bgdark-primary-grey;
  display: flex;
  font-size: 14px;
  justify-content: flex-start;
  min-height: 24px;
  opacity: 1;
  position: relative;
  transition: opacity 0.25s linear;

  &--space-between {
    justify-content: space-between;
  }

  &__title {
    color: $bgdark-secondary-grey;
    flex: 0 0 auto;
    font-size: 12px;
    line-height: 1;
    margin-right: 6px;
    text-transform: uppercase;

    + .property-setting__list {
      margin-left: 18px;
    }
  }

  &__description {
    color: $bgdark-primary-grey;
    flex: 0 0 auto;
    font-size: 12px;
    line-height: 1;
    margin-right: 6px;
  }
}
