// widget options lists and nesting
// nesting allows for multiple items to
// be grouped together from the config file
.nested-view {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  // boolean items should not flex
  &__item:not(.boolean) {
    flex: 1 1 auto;
    width: 100%;
  }

  &__item.boolean {
    margin: 6px 0 0;
    min-width: 48px;

    &:first-child {
      margin-left: 12px;
    }
  }

  .lp-checkbox {
    margin: 0;
    height: 0;
    visibility: hidden;

    &.is-visible {
      height: auto;
      margin: 12px 0;
      visibility: visible;
    }
  }
}
// basic menu list
.menu-list {
  border-top: 1px solid transparentize($bgdark-primary-grey, 0.5);
  list-style: none;
  margin: 0;

  .nested-view & {
    border-top: 0;
  }
  //modifiers for alignment
  &.left-align {
    text-align: left;
  }

  &.center-align {
    text-align: center;
  }

  li {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    position: relative;
    width: 100%;

    .item-is-active {
      &::before {
        content: '';
        display: none;
      }
    }

    &.item-active {
      .item-is-active {
        position: relative;

        &::before {
          color: $lp-blue;
          content: '\e206';
          display: inline-block;
          font-family: 'LeadPages-Icons';
          font-style: normal;
          line-height: 1;
          position: absolute;
          right: calc(100% + 5px);
          top: 2px;
        }
      }
    }

    .lp-icon {
      flex: 1 1 auto;
    }
  }
}
// overflow list has set height and scrolls the overflow-container
// wrap inside a div and apply the container class
.overflow-container {
  border-bottom: 1px solid transparentize($bgdark-primary-grey, 0.6);
  border-top: 1px solid transparentize($bgdark-primary-grey, 0.6);
}

.overflow-list {
  height: 118px;
  overflow: hidden;
  padding: 5px 30px;
  position: relative;
}
// normal list does not scroll overflow
.normal-list {
  display: flex;
  padding: 6px 0;
  width: 100%;

  li {
    margin: 5px 0;
  }
}

.vertical-list {
  flex-direction: column;
}

.horizontal-list {
  flex-direction: row;
}

.tab-menu-constrained {
  margin: 0 auto;
  width: 210px;

  &--no-tabs {
    width: 100%;
    border-bottom: 1px solid rgba(177, 186, 204, 0.5);
  }
}

// NOTE the menu toggle is located trumps.widget-options.scss
.overlay-toolbar {
  min-height: 48px;
  position: relative;

  &.embed-toolbar {
    color: $white;
    font-size: 14px;
    line-height: 48px;
  }
}

.number-field-options,
.text-field-options {
  .ui-form {
    align-items: center;
    display: flex;
    padding: 12px;

    .lp-label {
      display: inline-block;
      margin-right: 6px;
      min-width: 36px;
      vertical-align: middle;
    }

    // LEGO trump
    .ui-input {
      margin-top: 0;
    }
  }

  .help-text {
    color: $bgdark-primary-grey;
    padding: 0 12px 12px;
    text-align: left;
  }
}

.nested-list-range-slider {
  &__slider {
    display: block;
    max-height: 40px;
    opacity: 1;
    transition: max-height 0.2s ease, opacity 0.3s ease;

    &.is-hidden {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }
}

// HTML AND EMBED TEXT AREA
// LEGO TRUMP
.btn__widget-menu {
  .ui-textarea {
    height: 156px;
    margin: 12px auto;
    width: 223px;
  }
}

.btn__widget-menu--color {
  .ui-tabs {
    > li {
      padding: 12px 6px;
    }
  }
}

.btn__widget-menu--opentable,
.btn__widget-menu--calendly-url {
  padding-left: 16px;
  padding-right: 16px;
}

.help-content {
  display: flex;
  justify-content: space-between;
}

.learn-more {
  text-align: left;

  a {
    color: $lp-light-blue-alt;
    display: inline-block;
    font-size: 14px;
  }
}

// Visible checks for fields

.nested-list-field {
  max-height: 0;
  opacity: 0;
  padding: 0 16px;
  transition: max-height 0.3s ease, opacity 0.3s ease;

  &.is-visible {
    max-height: 100vh;
    opacity: 1;
  }
}

.range-slider-field {
  max-height: 0;
  opacity: 0;
  padding-left: 12px;
  padding-right: 12px;
  transition: max-height 0.3s ease, opacity 0.3s ease;

  &.is-visible {
    max-height: 40px;
    opacity: 1;
  }
}

.icon-field {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;

  &.is-visible {
    max-height: 100vh;
    opacity: 1;
  }
}

.nested-link-field {
  height: 0;
  overflow: hidden;
}
