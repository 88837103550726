//styles for configure-checkbox-list and configure-radio-list

$valueWidth: 50%;

.form-list-header {
  display: flex;
  font-size: 0.875rem;

  &__label {
    display: flex;
    position: relative;
    width: 100%;
  }

  &__value {
    display: flex;
    justify-content: center;
    width: $valueWidth;
  }
}

.form-list {
  .multi-input {
    align-items: center;
    display: flex;

    .ui-radio {
      .ui-radio__label {
        min-height: 15px; // Checkbox does this in Lego, but not radio.
      }
    }

    .input-item {
      background: transparent;
      border: 1px solid transparent;
      margin: 3px 12px 3px 0;
      padding: 0px 0;
      transition: 0.3s border, 0.3s padding;

      .ui-checkbox__label:before,
      .ui-radio__label:before {
        content: '•';
      }
    }

    &__label {
      display: flex;
      justify-content: center;
      width: $valueWidth;

      h6 {
        color: $greyscale-07;
        font-size: 0.625rem; //10/16
        font-weight: normal;
        margin: 0;
      }
    }

    &__container {
      display: flex;
      position: relative;
      width: 100%;
    }

    .input-item-delete {
      background: none;
      border: 0;
      color: $greyscale-07;
      font-size: 1rem; //16
      height: 24px;
      opacity: 0;
      outline: 0;
      position: absolute;
      right: 18px;
      top: 9px;
      transition: 0.3s opacity;

      &.is-visible {
        opacity: 1;
      }

      &:hover,
      &:focus {
        color: $bglight-secondary-grey;

        + .input-item {
          //maintains input hover
          border: 1px solid $greyscale-07;
          padding: 12px;

          &:focus {
            border: 1px solid $lp-blue;
            padding: 12px;
          }
        }
      }
    }

    input.lb-sup-checkbox + label span {
      border-color: $greyscale-07;
      margin: 0;
    }

    input.lp-sup-radio + label span {
      margin: 3px;
    }
  }

  .checkbox-input__label {
    width: 18%;
  }

  &__add-item {
    a {
      align-items: center;
      color: $greyscale-07;
      display: flex;
      font-size: 0.75rem; //12/16
      transition: 0.3s color;

      &:hover {
        color: $bglight-secondary-grey;
      }
    }

    .lp-icon {
      font-size: 1rem; //16
      margin: 12px 6px 12px 0;
      text-align: center;
      width: 16px;
    }
  }
}
