.responsive-page-container {
  background-color: $lp-greyDarkest;
  .device-editor,
  .device-editor-background {
    width: 100%;
    transition: all 0.5s ease-in-out;
  }

  .device-editor {
    position: relative;
    container-type: inline-size;
    // Create a new stacking context so that everything inside the editor works as expected
    transform: translateX(0);
    z-index: 1;
  }

  .device-editor-background {
    height: calc(100vh - 72px);
    margin: 0 auto;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .video-background-container {
    height: calc(100vh - 72px);
    width: 100%;
    position: fixed;
    contain: paint;
  }

  &.responsive {
    bottom: 0;
    left: 0;
    margin: auto;
    right: 0;
    top: 0;

    .device-editor,
    .device-editor-background,
    .video-background-container {
      border-radius: 10px;
    }

    .device-editor {
      outline: 1px solid $lp-greyDarker;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        box-shadow: 0 10px 0 0 $lp-greyDarkest;
        pointer-events: none;
      }
    }

    // Centering the logo when other columns collapses
    &.mobile .flexed-header {
      & .layout .column:nth-of-type(1) {
        flex: 1 1 auto;
      }
    }
  }

  &.tablet {
    .device-editor,
    .device-editor-background,
    .video-background-container {
      width: 768px;
    }
  }

  &.mobile {
    .device-editor,
    .device-editor-background,
    .video-background-container {
      width: 375px;
    }
  }

  .site-header {
    &.sticky {
      position: sticky;
    }
  }

  .lp-space-react {
    @container (max-width: 767px) {
      height: 6px;
    }
  }

  .video-background {
    z-index: 0;
  }
}
