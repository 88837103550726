// styles specific needed for preview pane
// not needed for if binding
template-editor {
  display: block;
  position: relative;
  transition: transform 0.5s ease-in-out;
  z-index: layer(template-editor);
  opacity: 1;
}

template-header {
  display: block;
  position: relative;
  transform: translate3d(0, 0, 2px);
  z-index: layer(template-header);
}

.side-nav {
  transition: all 0.3s;
}

preview-pane {
  background-color: $greyscale-09;
  display: block;
  height: 100vh;
  min-height: 100%;
  opacity: 0;
  position: relative;
  transform: translate3d(0, 100vh, 1px);
  transition: all 0.5s ease-in-out;

  iframe {
    background-color: $white;
    border: 0;
    bottom: 0;
    left: 0;
    min-height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
}

.device {
  height: calc(100vh - 72px);
  margin: 0 auto;
  max-height: 100%;
  position: relative;
  transition: all 0.5s ease-in-out;
  width: 100%;
  z-index: layer(device);
}

.preview-container {
  &.responsive {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;

    .device {
      height: calc(80vh - 72px);
      margin-top: 20vh;

      &__earpiece {
        border: 2px solid $greyscale-09;
        border-radius: 4px;
        height: 8px;
        left: 50%;
        position: absolute;
        top: -32px;
        transform: translateX(-50%);
        transition: all 0.3s ease-in-out;
      }

      &__button {
        border: 2px solid $greyscale-09;
        border-radius: 50%;
        bottom: -59px;
        height: 40px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 40px;
      }
    }

    iframe {
      box-sizing: content-box;
    }
  }

  &.tablet {
    .device {
      max-height: 1024px;
      width: 768px;

      iframe {
        max-height: 1024px;
      }

      &__earpiece {
        width: 8px;
      }

      &__button {
        bottom: -74px;
      }

      // the css representation of device
      &::before {
        background-color: $white;
        border: 2px solid $greyscale-08;
        border-radius: 55px;
        bottom: -132px;
        content: '';
        left: -36px;
        position: absolute;
        right: -36px;
        top: -84px;
      }
    }
  }

  &.mobile {
    .device {
      max-height: 559px;
      width: 375px;

      // the css representation of device
      &::before {
        background-color: $white;
        border: 2px solid $greyscale-08;
        border-radius: 36px;
        bottom: -84px;
        content: '';
        left: -12px;
        position: absolute;
        right: -12px;
        top: -68px;
      }

      &__earpiece {
        width: 48px;

        &::before {
          border: 2px solid $greyscale-09;
          border-radius: 4px;
          bottom: 0;
          content: '';
          height: 8px;
          margin: auto;
          position: absolute;
          right: calc(100% + 12px);
          top: 0;
          width: 8px;
        }
      }
    }
  }
}
