input.lb-sup-checkbox[type='checkbox'] {
  display: none;

  + label {
    align-items: center;
    cursor: pointer;
    display: flex;

    &:hover span::after,
    &:focus span::after,
    &:active span::after {
      opacity: 1;
    }
  }

  + label span {
    background: $white;
    border: 2px solid $bglight-secondary-grey;
    border-radius: 2px;
    display: inline-block;
    height: 15px;
    margin: 0 6px 0 0;
    transition: 0.3s background;
    width: 15px;

    &::after {
      color: $greyscale-08;
      content: '\e206';
      display: flex;
      font-family: 'LeadPages-Icons';
      font-size: 11px;
      margin: -2px 0 0;
      opacity: 0;
      transition: 0.3s opacity, 0.3s color;
    }
  }

  &:checked + label span::after {
    color: $lp-blue;
    opacity: 1;
  }
}
