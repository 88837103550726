.lp-nav {
  &.flex-start,
  &.flex-end {
    @container (min-width: 960px) {
      & .lp-nav__link-container:last-child .lp-nav__link {
        margin-right: 12px;

        &.lp-subnav__link {
          margin-right: 0px;
        }
      }

      & .lp-nav__link-container:first-child .lp-nav__link {
        margin-left: 12px;

        &.lp-subnav__link {
          margin-left: 0px;
        }
      }
    }
  }

  &.flex-start,
  &.flex-end,
  &.center {
    @container (max-width: 959px) {
      & .lp-nav__link {
        width: 100%;

        &:hover {
          &:after {
            left: -12px;
          }
        }

        &:after {
          left: -18px;
          height: 70%;
          width: 2px;
          bottom: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &.lp-nav__link--active {
          &:after {
            left: -12px;
          }
        }
      }
    }
  }
}
