@import 'settings.colors';
@import 'settings.variables';
@import 'settings.sizing';

.navigation {
  height: $lp-header-height !important;
  z-index: layer(main-navigation) !important;

  &__left {
    flex-grow: 1;
    min-width: 350px; // allow long page name truncation (see https://css-tricks.com/flexbox-truncated-text/)
  }

  &__entity-name {
    min-width: 0; // allow long page name truncation (see https://css-tricks.com/flexbox-truncated-text/)
  }

  &__back-button {
    padding-left: 48px !important;
    padding-right: 24px !important;
    transition-property: background-color, color;
    &-icon {
      position: absolute;
      top: 1px;
      left: 20px;
    }
  }

  &__text-button {
    display: inline-flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;

    &-badge {
      background-color: #8fefef;
      border-radius: 3px;
      padding: 2px 4px;
      margin-left: 10px;
      font-size: 13px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 2px;
    }
  }

  &__lead-rank {
    height: $lp-header-height;
    margin-left: -7px;
    padding: 0;
  }
}

@media only screen and (max-width: $break-nav) {
  .navigation {
    &__entity-name {
      max-width: 300px;
    }

    &__back-button {
      width: 36px;
      height: 36px;
      padding: 0 !important;
      background-color: transparent !important;

      &-label {
        display: none;
      }

      &-icon {
        position: relative;
        top: 0;
        left: 0;
        color: $leads-blue !important;
      }

      &:hover,
      &:focus {
        background-color: $leads-grey !important;

        .navigation__back-button-icon {
          color: $leads-grey-darker !important;
        }
      }
    }
  }
}
