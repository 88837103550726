$lp-widget-pane-width: 288px;

.panes-wrapper {
  &.editor-pane-is-open,
  &.form-v2-editor-pane-is-open,
  &.widget-sidebar-is-open {
    template-editor {
      height: calc(100% + 72px);
    }
  }

  &.editor-pane-is-open {
    .oc-editor-pane-wrap.form-editor {
      flex: 0 0 478px;
      z-index: layer(editor-pane-open);
    }
  }

  &.form-v2-editor-pane-is-open {
    .oc-editor-pane-wrap.form-v2-editor {
      flex: 0 0 478px;
      z-index: layer(editor-pane-open);
    }

    .oc-editor-pane {
      overflow-y: hidden;
    }
  }

  &.widget-sidebar-is-open {
    .oc-editor-pane-wrap.widget-sidebar {
      margin: 0;
      flex: 0 0 $lp-widget-pane-width;
      visibility: visible;
      opacity: 1;
      z-index: layer(widget-sidebar-open-off-canvas-pane);
    }

    .oc-editor-pane {
      width: $lp-widget-pane-width;
    }
  }

  &.a-i-copy-pane-is-open .aicopy-pane,
  &.settings-pane-is-open .settings-pane,
  &.page-style-pane-is-open .style-pane {
    width: 408px;
  }
}

.side-nav {
  .editor-pane-is-open &,
  .form-v2-editor-pane-is-open &,
  .leadbox-widget-sidebar-is-open & {
    opacity: 0;
    visibility: hidden;
    z-index: layer(widget-sidebar-open-side-nav); // down from 101
  }
}

.side-panes {
  background: $lp-greyDarkest;
  border-right: 1px solid $lp-greyDarker;
  flex: 0 0 0%;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease-out;
  z-index: layer(side-panes);

  &--crucible-render {
    h4 {
      color: $white;
      font-size: 17px !important;
    }
  }

  .click-shield-is-active & {
    overflow: visible;
  }

  > .slide-container__slide:not(.animate-moveFromLeftFade) {
    left: auto;
    right: 0;
  }

  .section-pane-is-open &,
  .pages-pane-is-open &,
  .leadbox-widget-pane-is-open &,
  .widget-pane-is-open & {
    flex: 0 0 $lp-widget-pane-width;

    > .slide-container__slide {
      width: $lp-widget-pane-width;
    }
  }

  .bar-layout-pane-is-open & {
    flex: 0 0 298px;

    > .slide-container__slide {
      width: 298px;
    }
  }

  .a-i-copy-pane-is-open &,
  .settings-pane-is-open &,
  .tracking-pane-is-open &,
  .page-style-pane-is-open &,
  .layout-pane-is-open &,
  .leadbox-layout-pane-is-open & {
    flex: 0 0 408px;

    > .slide-container__slide {
      width: 408px;
    }
  }
}

tracking-pane {
  overflow-y: auto;
}

.template-pane {
  .settings-pane-is-open & {
    &::after {
      opacity: 1;
      transition: all 0.4s ease-in;
      visibility: visible;
    }
  }

  .is-in-preview-mode & {
    flex-basis: 100%;
  }
}

.lead-box-widget-pane {
  widget-template {
    &:nth-of-type(even) {
      margin-right: 6px;
    }

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

// HELP PANE
.help-pane {
  background-color: $lp-greyDarkest;
  color: $white;
  bottom: 0;
  box-shadow: 0 -8px 12px rgba(0, 0, 0, 0.23);
  position: fixed;
  left: 72px;
  top: $lp-header-height;
  transform: translateX(-100%) translateX(12px);
  transition: transform 0.4s ease-out;
  width: 420px;

  .help-is-open & {
    transform: translateX(0);

    &--minimize {
      transform: translateX(-100%) translateX(-50px);
    }
  }
}
