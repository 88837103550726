//want to detach the buttons from inuit since it will be using the framework for pages built
// these are pulled right from that
.lp-sup-btn {
  background-color: #4a8ec2;
  border: 1px solid #4a8ec2;
  border-radius: 24px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  height: 35px;
  margin: 0;
  min-width: 110px;
  opacity: 1;
  padding: 6px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  vertical-align: middle;

  &:focus {
    outline: 0;
  }

  &.is-loading {
    cursor: default;

    .loading-bar {
      .loading {
        height: 12px;
        width: 12px;
      }
    }
  }

  &--inverted {
    background-color: transparent;
    border-color: $white;
    color: $white;
    text-decoration: none;
    transition: all 0.3s;

    &:not(:disabled),
    &:not(.is-loading):hover,
    &:not(.is-loading):active,
    &:not(.is-loading):focus {
      background-color: $white;
      border-color: $white;
      color: #4a8ec2;
    }

    &:disabled,
    &:disabled:hover {
      background-color: transparent;
      border-color: $alternate-base-grey;
      color: $alternate-base-grey;
    }
  }

  &--cancel {
    background-color: transparent;
    border: 0;
    color: $bgdark-primary-grey;
    font-size: 0.875rem;
    outline: 0;
    text-decoration: none;
    transition: all 250ms ease;

    &:hover {
      color: $bgdark-secondary-grey;
    }
  }
  //checkmark animation on form save
  &.is-success {
    align-items: center;
    display: inline-flex;
    justify-content: center;

    i {
      animation: success 1s ease;
      animation-delay: 0.1s;
      animation-fill-mode: both;
      line-height: 1;
      transform: translateY(30px);
    }
  }
}

//Overrides for the main button class
.lp-sup-btn--primary {
  background-color: $lp-blue;
  border-color: $lp-blue;

  &.is-loading {
    background: transparentize($lp-blue, 0.5);
    border-color: transparent;

    .loading {
      background: $white;
    }
  }

  &:not(:disabled):not(.is-loading):hover,
  &:not(:disabled):not(.is-loading):active,
  &:not(:disabled):not(.is-loading):focus {
    background-color: $lp-blue-hover;
    border-color: $lp-blue-hover;
    color: $white;
  }

  &:disabled,
  &:disabled:hover {
    opacity: 0.4;
  }
}

.lp-sup-btn--secondary {
  background-color: transparent;
  border-color: $lp-blue;
  color: $lp-blue;

  &.is-loading {
    background: transparent;
    border-color: transparentize($lp-blue, 0.5);

    .loading-bar {
      .loading {
        background: $lp-blue;
      }
    }
  }

  &:not(.is-loading):hover,
  &:not(.is-loading):active,
  &:not(.is-loading):focus {
    background-color: $lp-blue;
    border-color: $lp-blue;
    color: $white;
  }

  &:disabled,
  &:disabled:hover {
    background-color: transparent;
    border-color: $lp-blue;
    color: $lp-blue;
    opacity: 0.4;
  }
}

.lp-sup-btn--circle {
  background-color: transparent;
  border-color: $bgdark-primary-grey;
  color: $bgdark-primary-grey;
  font-size: 1.5em;
  line-height: 1;
  min-width: 0;
  padding: 3px 6px;

  &:hover,
  &:active,
  &:focus {
    border-color: $lp-blue;
    color: $lp-blue;
  }
}

.ui-btn-group {
  .ui-btn:only-of-type {
    border-radius: 15em;
  }
}
