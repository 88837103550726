.form-editor {
  .help-link {
    background: $lp-greyDarkest;
    color: $bgdark-secondary-grey;
    padding: 12px;
    text-align: center;
    white-space: nowrap;
    width: 100%;

    &__text {
      max-width: 100%;
      overflow: hidden;
    }

    .feedback {
      // TODO: can be removed once fix is in LE&DS
      display: inline-block;
      margin-bottom: 12px;
      width: 36px;
      height: 36px;
      padding: 3px 0 0 0;
    }
  }
}
