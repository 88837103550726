.leadbox {
  .section-arrow--bottom {
    margin-bottom: 0 !important;
  }

  .section-wrapper:last-of-type .section-arrow--bottom {
    margin-bottom: 18px !important;
  }
}

column {
  .menu-container {
    top: 30px;
  }
}
