/* animation sets */
/* stylelint-disable */

/* move from / to  */

.animatemoveToLeft {
  animation: moveToLeft 0.4s ease both;
}

.animate-moveFromLeft {
  animation: moveFromLeft 0.4s 0.4s ease both;
}

.animate-moveToRight {
  animation: moveToRight 0.4s ease both;
}

.animate-moveFromRight {
  animation: moveFromRight 0.4s 0.4s ease both;
}

.animate-moveToTop {
  animation: moveToTop 0.4s ease both;
}

.animate-moveFromTop {
  animation: moveFromTop 0.4s ease both;
}

.animate-moveToBottom {
  animation: moveToBottom 0.4s ease both;
}

.animate-moveFromBottom {
  animation: moveFromBottom 0.4s ease both;
}

/* fade */

.animate-fade {
  animation: fade 0.7s ease both;
}

/* move from / to and fade */

.animate-moveToLeftFade {
  animation: moveToLeftFade 0.4s ease both;
}

.animate-moveFromLeftFade {
  animation: moveFromLeftFade 0.4s 0.1s ease both;
}

.animate-moveToRightFade {
  animation: moveToRightFade 0.4s ease both;
}

.animate-moveFromRightFade {
  animation: moveFromRightFade 0.4s 0.1s ease both;
}

.animate-moveToTopFade {
  animation: moveToTopFade 0.4s ease both;
}

.animate-moveFromTopFade {
  animation: moveFromTopFade 0.4s ease both;
}

.animate-moveToBottomFade {
  animation: moveToBottomFade 0.4s ease both;
}

.animate-moveFromBottomFade {
  animation: moveFromBottomFade 0.4s ease both;
}

/* move to with different easing */

.animate-moveToLeftEasing {
  animation: moveToLeft 0.7s ease-in-out both;
}
.animate-moveToRightEasing {
  animation: moveToRight 0.7s ease-in-out both;
}
.animate-moveToTopEasing {
  animation: moveToTop 0.7s ease-in-out both;
}
.animate-moveToBottomEasing {
  animation: moveToBottom 0.7s ease-in-out both;
}

/********************************* keyframes **************************************/

/* move from / to  */

@keyframes moveToLeft {
  from {
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes moveFromLeft {
  from {
    transform: translate3d(-100%, 0, 1px);
  }
}

@keyframes moveToRight {
  from {
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes moveFromRight {
  from {
    transform: translate3d(100%, 0, 1px);
  }
}

@keyframes moveToTop {
  from {
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes moveFromTop {
  from {
    transform: translateY(-100%);
  }
}

@keyframes moveToBottom {
  from {
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes moveFromBottom {
  from {
    transform: translateY(100%);
  }
}

/* fade */

@keyframes fade {
  from {
  }
  to {
    opacity: 0;
  }
}

/* move from / to and fade */

@keyframes moveToLeftFade {
  from {
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes moveFromLeftFade {
  from {
    opacity: 0;
    transform: translateX(-20%);
  }
}

@keyframes moveToRightFade {
  from {
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes moveFromRightFade {
  from {
    opacity: 0;
    transform: translateX(20%);
  }
}

@keyframes moveToTopFade {
  from {
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes moveFromTopFade {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes moveToBottomFade {
  from {
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes moveFromBottomFade {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
}

/* scale and fade */

.animate-scaleDown {
  animation: scaleDown 0.4s ease both;
}

.animate-scaleUp {
  animation: scaleUp 0.7s ease both;
}

.animate-scaleUpDown {
  animation: scaleUpDown 0.5s ease both;
}

.animate-scaleDownUp {
  animation: scaleDownUp 0.5s ease both;
}

.animate-scaleDownCenter {
  animation: scaleDownCenter 0.4s ease-in both;
}

.animate-scaleUpCenter {
  animation: scaleUpCenter 0.4s ease-out both;
}

/********************************* keyframes **************************************/

/* scale and fade */

@keyframes scaleDown {
  from {
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

@keyframes scaleUp {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
}

@keyframes scaleUpDown {
  from {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes scaleDownUp {
  from {
  }
  to {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes scaleDownCenter {
  from {
  }
  to {
    opacity: 0;
    transform: scale(0.7);
  }
}

@keyframes scaleUpCenter {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
}

/* rotate sides first and scale */

.animate-rotateRightSideFirst {
  transform-origin: 0% 50%;
  animation: rotateRightSideFirst 0.8s both ease-in;
}
.animate-rotateLeftSideFirst {
  transform-origin: 100% 50%;
  animation: rotateLeftSideFirst 0.8s both ease-in;
}
.animate-rotateTopSideFirst {
  transform-origin: 50% 100%;
  animation: rotateTopSideFirst 0.8s both ease-in;
}
.animate-rotateBottomSideFirst {
  transform-origin: 50% 0%;
  animation: rotateBottomSideFirst 0.8s both ease-in;
}

/* flip */

.animate-flipOutRight {
  transform-origin: 50% 50%;
  animation: flipOutRight 0.5s both ease-in;
}
.animate-flipInLeft {
  transform-origin: 50% 50%;
  animation: flipInLeft 0.5s both ease-out;
}
.animate-flipOutLeft {
  transform-origin: 50% 50%;
  animation: flipOutLeft 0.5s both ease-in;
}
.animate-flipInRight {
  transform-origin: 50% 50%;
  animation: flipInRight 0.5s both ease-out;
}
.animate-flipOutTop {
  transform-origin: 50% 50%;
  animation: flipOutTop 0.5s both ease-in;
}
.animate-flipInBottom {
  transform-origin: 50% 50%;
  animation: flipInBottom 0.5s both ease-out;
}
.animate-flipOutBottom {
  transform-origin: 50% 50%;
  animation: flipOutBottom 0.5s both ease-in;
}
.animate-flipInTop {
  transform-origin: 50% 50%;
  animation: flipInTop 0.5s both ease-out;
}

/* rotate fall */

.animate-rotateFall {
  transform-origin: 0% 0%;
  animation: rotateFall 1s both ease-in;
}

/* rotate newspaper */
.animate-rotateOutNewspaper {
  transform-origin: 50% 50%;
  animation: rotateOutNewspaper 0.5s both ease-in;
}
.animate-rotateInNewspaper {
  transform-origin: 50% 50%;
  animation: rotateInNewspaper 0.5s both ease-out;
}

/* push */
.animate-rotatePushLeft {
  transform-origin: 0% 50%;
  animation: rotatePushLeft 0.8s both ease;
}
.animate-rotatePushRight {
  transform-origin: 100% 50%;
  animation: rotatePushRight 0.8s both ease;
}
.animate-rotatePushTop {
  transform-origin: 50% 0%;
  animation: rotatePushTop 0.8s both ease;
}
.animate-rotatePushBottom {
  transform-origin: 50% 100%;
  animation: rotatePushBottom 0.8s both ease;
}

/* pull */
.animate-rotatePullRight {
  transform-origin: 100% 50%;
  animation: rotatePullRight 0.5s both ease;
}
.animate-rotatePullLeft {
  transform-origin: 0% 50%;
  animation: rotatePullLeft 0.5s both ease;
}
.animate-rotatePullTop {
  transform-origin: 50% 0%;
  animation: rotatePullTop 0.5s both ease;
}
.animate-rotatePullBottom {
  transform-origin: 50% 100%;
  animation: rotatePullBottom 0.5s both ease;
}

/* fold */
.animate-rotateFoldRight {
  transform-origin: 0% 50%;
  animation: rotateFoldRight 0.7s both ease;
}
.animate-rotateFoldLeft {
  transform-origin: 100% 50%;
  animation: rotateFoldLeft 0.7s both ease;
}
.animate-rotateFoldTop {
  transform-origin: 50% 100%;
  animation: rotateFoldTop 0.7s both ease;
}
.animate-rotateFoldBottom {
  transform-origin: 50% 0%;
  animation: rotateFoldBottom 0.7s both ease;
}

/* unfold */
.animate-rotateUnfoldLeft {
  transform-origin: 100% 50%;
  animation: rotateUnfoldLeft 0.7s both ease;
}
.animate-rotateUnfoldRight {
  transform-origin: 0% 50%;
  animation: rotateUnfoldRight 0.7s both ease;
}
.animate-rotateUnfoldTop {
  transform-origin: 50% 100%;
  animation: rotateUnfoldTop 0.7s both ease;
}
.animate-rotateUnfoldBottom {
  transform-origin: 50% 0%;
  animation: rotateUnfoldBottom 0.7s both ease;
}

/* room walls */
.animate-rotateRoomLeftOut {
  transform-origin: 100% 50%;
  animation: rotateRoomLeftOut 0.8s both ease;
}
.animate-rotateRoomLeftIn {
  transform-origin: 0% 50%;
  animation: rotateRoomLeftIn 0.8s both ease;
}
.animate-rotateRoomRightOut {
  transform-origin: 0% 50%;
  animation: rotateRoomRightOut 0.8s both ease;
}
.animate-rotateRoomRightIn {
  transform-origin: 100% 50%;
  animation: rotateRoomRightIn 0.8s both ease;
}
.animate-rotateRoomTopOut {
  transform-origin: 50% 100%;
  animation: rotateRoomTopOut 0.8s both ease;
}
.animate-rotateRoomTopIn {
  transform-origin: 50% 0%;
  animation: rotateRoomTopIn 0.8s both ease;
}
.animate-rotateRoomBottomOut {
  transform-origin: 50% 0%;
  animation: rotateRoomBottomOut 0.8s both ease;
}
.animate-rotateRoomBottomIn {
  transform-origin: 50% 100%;
  animation: rotateRoomBottomIn 0.8s both ease;
}

/* cube */
.animate-rotateCubeLeftOut {
  transform-origin: 100% 50%;
  animation: rotateCubeLeftOut 0.4s both ease-in;
}
.animate-rotateCubeLeftIn {
  transform-origin: 0% 50%;
  animation: rotateCubeLeftIn 0.4s both ease-in;
}
.animate-rotateCubeRightOut {
  transform-origin: 0% 50%;
  animation: rotateCubeRightOut 0.4s both ease-in;
}
.animate-rotateCubeRightIn {
  transform-origin: 100% 50%;
  animation: rotateCubeRightIn 0.4s both ease-in;
}
.animate-rotateCubeTopOut {
  transform-origin: 50% 100%;
  animation: rotateCubeTopOut 0.4s both ease-in;
}
.animate-rotateCubeTopIn {
  transform-origin: 50% 0%;
  animation: rotateCubeTopIn 0.4s both ease-in;
}
.animate-rotateCubeBottomOut {
  transform-origin: 50% 0%;
  animation: rotateCubeBottomOut 0.4s both ease-in;
}
.animate-rotateCubeBottomIn {
  transform-origin: 50% 100%;
  animation: rotateCubeBottomIn 0.4s both ease-in;
}

/* carousel */
.animate-rotateCarouselLeftOut {
  transform-origin: 100% 50%;
  animation: rotateCarouselLeftOut 0.8s both ease;
}
.animate-rotateCarouselLeftIn {
  transform-origin: 0% 50%;
  animation: rotateCarouselLeftIn 0.8s both ease;
}
.animate-rotateCarouselRightOut {
  transform-origin: 0% 50%;
  animation: rotateCarouselRightOut 0.8s both ease;
}
.animate-rotateCarouselRightIn {
  transform-origin: 100% 50%;
  animation: rotateCarouselRightIn 0.8s both ease;
}
.animate-rotateCarouselTopOut {
  transform-origin: 50% 100%;
  animation: rotateCarouselTopOut 0.8s both ease;
}
.animate-rotateCarouselTopIn {
  transform-origin: 50% 0%;
  animation: rotateCarouselTopIn 0.8s both ease;
}
.animate-rotateCarouselBottomOut {
  transform-origin: 50% 0%;
  animation: rotateCarouselBottomOut 0.8s both ease;
}
.animate-rotateCarouselBottomIn {
  transform-origin: 50% 100%;
  animation: rotateCarouselBottomIn 0.8s both ease;
}

/* sides */
.animate-rotateSidesOut {
  transform-origin: -50% 50%;
  animation: rotateSidesOut 0.5s both ease-in;
}
.animate-rotateSidesIn {
  transform-origin: 150% 50%;
  animation: rotateSidesIn 0.5s both ease-out;
}

/* slide */
.animate-rotateSlideOut {
  animation: rotateSlideOut 1s both ease;
}
.animate-rotateSlideIn {
  animation: rotateSlideIn 1s both ease;
}

/********************************* keyframes **************************************/

/* rotate sides first and scale */

@keyframes rotateRightSideFirst {
  0% {
  }
  40% {
    transform: rotateY(15deg);
    opacity: 0.8;
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}

@keyframes rotateLeftSideFirst {
  0% {
  }
  40% {
    transform: rotateY(-15deg);
    opacity: 0.8;
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}

@keyframes rotateTopSideFirst {
  0% {
  }
  40% {
    transform: rotateX(15deg);
    opacity: 0.8;
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}

@keyframes rotateBottomSideFirst {
  0% {
  }
  40% {
    transform: rotateX(-15deg);
    opacity: 0.8;
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(0.8) translateZ(-200px);
    opacity: 0;
  }
}

/* flip */

@keyframes flipOutRight {
  from {
  }
  to {
    transform: translateZ(-1000px) rotateY(90deg);
    opacity: 0.2;
  }
}

@keyframes flipInLeft {
  from {
    transform: translateZ(-1000px) rotateY(-90deg);
    opacity: 0.2;
  }
}

@keyframes flipOutLeft {
  from {
  }
  to {
    transform: translateZ(-1000px) rotateY(-90deg);
    opacity: 0.2;
  }
}

@keyframes flipInRight {
  from {
    transform: translateZ(-1000px) rotateY(90deg);
    opacity: 0.2;
  }
}

@keyframes flipOutTop {
  from {
  }
  to {
    transform: translateZ(-1000px) rotateX(90deg);
    opacity: 0.2;
  }
}

@keyframes flipInBottom {
  from {
    transform: translateZ(-1000px) rotateX(-90deg);
    opacity: 0.2;
  }
}

@keyframes flipOutBottom {
  from {
  }
  to {
    transform: translateZ(-1000px) rotateX(-90deg);
    opacity: 0.2;
  }
}

@keyframes flipInTop {
  from {
    transform: translateZ(-1000px) rotateX(90deg);
    opacity: 0.2;
  }
}

/* fall */

@keyframes rotateFall {
  0% {
    transform: rotateZ(0deg);
  }
  20% {
    transform: rotateZ(10deg);
    animation-timing-function: ease-out;
  }
  40% {
    transform: rotateZ(17deg);
  }
  60% {
    transform: rotateZ(16deg);
  }
  100% {
    transform: translateY(100%) rotateZ(17deg);
  }
}

/* newspaper */

@keyframes rotateOutNewspaper {
  from {
  }
  to {
    transform: translateZ(-3000px) rotateZ(360deg);
    opacity: 0;
  }
}

@keyframes rotateInNewspaper {
  from {
    transform: translateZ(-3000px) rotateZ(-360deg);
    opacity: 0;
  }
}

/* push */

@keyframes rotatePushLeft {
  from {
  }
  to {
    opacity: 0;
    transform: rotateY(90deg);
  }
}

@keyframes rotatePushRight {
  from {
  }
  to {
    opacity: 0;
    transform: rotateY(-90deg);
  }
}

@keyframes rotatePushTop {
  from {
  }
  to {
    opacity: 0;
    transform: rotateX(-90deg);
  }
}

@keyframes rotatePushBottom {
  from {
  }
  to {
    opacity: 0;
    transform: rotateX(90deg);
  }
}

/* pull */

@keyframes rotatePullRight {
  from {
    opacity: 0;
    transform: rotateY(-90deg);
  }
}

@keyframes rotatePullLeft {
  from {
    opacity: 0;
    transform: rotateY(90deg);
  }
}

@keyframes rotatePullTop {
  from {
    opacity: 0;
    transform: rotateX(-90deg);
  }
}

@keyframes rotatePullBottom {
  from {
    opacity: 0;
    transform: rotateX(90deg);
  }
}

/* fold */

@keyframes rotateFoldRight {
  from {
  }
  to {
    opacity: 0;
    transform: translateX(100%) rotateY(90deg);
  }
}

@keyframes rotateFoldLeft {
  from {
  }
  to {
    opacity: 0;
    transform: translateX(-100%) rotateY(-90deg);
  }
}

@keyframes rotateFoldTop {
  from {
  }
  to {
    opacity: 0;
    transform: translateY(-100%) rotateX(90deg);
  }
}

@keyframes rotateFoldBottom {
  from {
  }
  to {
    opacity: 0;
    transform: translateY(100%) rotateX(-90deg);
  }
}

/* unfold */

@keyframes rotateUnfoldLeft {
  from {
    opacity: 0;
    transform: translateX(-100%) rotateY(-90deg);
  }
}

@keyframes rotateUnfoldRight {
  from {
    opacity: 0;
    transform: translateX(100%) rotateY(90deg);
  }
}

@keyframes rotateUnfoldTop {
  from {
    opacity: 0;
    transform: translateY(-100%) rotateX(90deg);
  }
}

@keyframes rotateUnfoldBottom {
  from {
    opacity: 0;
    transform: translateY(100%) rotateX(-90deg);
  }
}

/* room walls */

@keyframes rotateRoomLeftOut {
  from {
  }
  to {
    opacity: 0.3;
    transform: translateX(-100%) rotateY(90deg);
  }
}

@keyframes rotateRoomLeftIn {
  from {
    opacity: 0.3;
    transform: translateX(100%) rotateY(-90deg);
  }
}

@keyframes rotateRoomRightOut {
  from {
  }
  to {
    opacity: 0.3;
    transform: translateX(100%) rotateY(-90deg);
  }
}

@keyframes rotateRoomRightIn {
  from {
    opacity: 0.3;
    transform: translateX(-100%) rotateY(90deg);
  }
}

@keyframes rotateRoomTopOut {
  from {
  }
  to {
    opacity: 0.3;
    transform: translateY(-100%) rotateX(-90deg);
  }
}

@keyframes rotateRoomTopIn {
  from {
    opacity: 0.3;
    transform: translateY(100%) rotateX(90deg);
  }
}

@keyframes rotateRoomBottomOut {
  from {
  }
  to {
    opacity: 0.3;
    transform: translateY(100%) rotateX(90deg);
  }
}

@keyframes rotateRoomBottomIn {
  from {
    opacity: 0.3;
    transform: translateY(-100%) rotateX(-90deg);
  }
}

/* cube */

@keyframes rotateCubeLeftOut {
  0% {
  }
  50% {
    animation-timing-function: ease-out;
    transform: translateX(-50%) translateZ(-200px) rotateY(-45deg);
  }
  100% {
    opacity: 0.3;
    transform: translateX(-100%) rotateY(-90deg);
  }
}

@keyframes rotateCubeLeftIn {
  0% {
    opacity: 0.3;
    transform: translateX(100%) rotateY(90deg);
  }
  50% {
    animation-timing-function: ease-out;
    transform: translateX(50%) translateZ(-200px) rotateY(45deg);
  }
}

@keyframes rotateCubeRightOut {
  0% {
  }
  50% {
    animation-timing-function: ease-out;
    transform: translateX(50%) translateZ(-200px) rotateY(45deg);
  }
  100% {
    opacity: 0.3;
    transform: translateX(100%) rotateY(90deg);
  }
}

@keyframes rotateCubeRightIn {
  0% {
    opacity: 0.3;
    transform: translateX(-100%) rotateY(-90deg);
  }
  50% {
    animation-timing-function: ease-out;
    transform: translateX(-50%) translateZ(-200px) rotateY(-45deg);
  }
}

@keyframes rotateCubeTopOut {
  0% {
  }
  50% {
    animation-timing-function: ease-out;
    transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
  }
  100% {
    opacity: 0.3;
    transform: translateY(-100%) rotateX(90deg);
  }
}

@keyframes rotateCubeTopIn {
  0% {
    opacity: 0.3;
    transform: translateY(100%) rotateX(-90deg);
  }
  50% {
    animation-timing-function: ease-out;
    transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
  }
}

@keyframes rotateCubeBottomOut {
  0% {
  }
  50% {
    animation-timing-function: ease-out;
    transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
  }
  100% {
    opacity: 0.3;
    transform: translateY(100%) rotateX(-90deg);
  }
}

@keyframes rotateCubeBottomIn {
  0% {
    opacity: 0.3;
    transform: translateY(-100%) rotateX(90deg);
  }
  50% {
    animation-timing-function: ease-out;
    transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
  }
}

/* carousel */

@keyframes rotateCarouselLeftOut {
  from {
  }
  to {
    opacity: 0.3;
    transform: translateX(-150%) scale(0.4) rotateY(-65deg);
  }
}

@keyframes rotateCarouselLeftIn {
  from {
    opacity: 0.3;
    transform: translateX(200%) scale(0.4) rotateY(65deg);
  }
}

@keyframes rotateCarouselRightOut {
  from {
  }
  to {
    opacity: 0.3;
    transform: translateX(200%) scale(0.4) rotateY(65deg);
  }
}

@keyframes rotateCarouselRightIn {
  from {
    opacity: 0.3;
    transform: translateX(-200%) scale(0.4) rotateY(-65deg);
  }
}

@keyframes rotateCarouselTopOut {
  from {
  }
  to {
    opacity: 0.3;
    transform: translateY(-200%) scale(0.4) rotateX(65deg);
  }
}

@keyframes rotateCarouselTopIn {
  from {
    opacity: 0.3;
    transform: translateY(200%) scale(0.4) rotateX(-65deg);
  }
}

@keyframes rotateCarouselBottomOut {
  from {
  }
  to {
    opacity: 0.3;
    transform: translateY(200%) scale(0.4) rotateX(-65deg);
  }
}

@keyframes rotateCarouselBottomIn {
  from {
    opacity: 0.3;
    transform: translateY(-200%) scale(0.4) rotateX(65deg);
  }
}

/* sides */

@keyframes rotateSidesOut {
  from {
  }
  to {
    opacity: 0;
    transform: translateZ(-500px) rotateY(90deg);
  }
}

@keyframes rotateSidesIn {
  from {
    opacity: 0;
    transform: translateZ(-500px) rotateY(-90deg);
  }
}

/* slide */

@keyframes rotateSlideOut {
  0% {
  }
  25% {
    opacity: 0.5;
    transform: translateZ(-500px);
  }
  75% {
    opacity: 0.5;
    transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: 0.5;
    transform: translateZ(-500px) translateX(-200%);
  }
}

@keyframes rotateSlideIn {
  0%,
  25% {
    opacity: 0.5;
    transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: 0.5;
    transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0);
  }
}

/* animation delay classes */

.animate-delay100 {
  animation-delay: 0.1s;
}
.animate-delay180 {
  animation-delay: 0.18s;
}
.animate-delay200 {
  animation-delay: 0.2s;
}
.animate-delay300 {
  animation-delay: 0.3s;
}
.animate-delay400 {
  animation-delay: 0.4s;
}
.animate-delay500 {
  animation-delay: 0.5s;
}
.animate-delay700 {
  animation-delay: 0.7s;
}
.animate-delay1000 {
  animation-delay: 1s;
}
/* stylelint-enable */
