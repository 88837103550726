editable-name {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 100%;
}

.editable-name {
  &__text {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-left: 1px;

    &__content {
      max-width: 162px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__edit-btn {
      &:hover {
        color: $lp-blue;
      }

      &.is-hidden {
        opacity: 0;
        visibility: hidden;
      }

      &.is-visible {
        opacity: 1;
        visibility: visible;
      }

      .lp-icon {
        font-size: 15px;
        margin: 0 0 3px 3px;
      }
    }
  }

  &__form {
    align-items: center;
    background-color: $lp-navy;
    display: flex;
    justify-content: space-between;
    left: 0;
    position: absolute;
    top: -7px;
    width: 100%;
    z-index: layer(editable-name-form);

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }

    .ui-input {
      background-color: $lp-navy;
      flex: 0 1 auto;
      min-height: 32px;
      padding: 3px 6px;
      width: calc(100% - 64px); // 52px + 12px
    }

    .ui-btn--sm {
      height: 32px;
      min-width: 52px;
      padding: 5px 8px;
    }
  }
}
