input[type='range'] {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  margin: 18px 0;
  padding: 0;
  width: 100%;

  &:focus,
  &:active {
    outline: 0;
  }
}

// RANGE CONTAINER
.range {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 36px;
  position: relative;

  &__title {
    flex: 1 0 60px;
    font-size: 12px;
    letter-spacing: 1px;
    margin-right: 6px;
    text-align: right;
    text-transform: uppercase;
  }

  &__value {
    flex: 0 0 55px;
    text-align: left;
    transform: scale(1);
    transition: transform 0.4s ease;
  }

  &__slider {
    display: flex;
    flex: 1 1 auto;
    margin: 0 12px;

    // NOTE: Ensure slider track is 4px tall in Firefox.
    > .slider {
      height: 4px;
    }

    &:active {
      + .range__value {
        transform: scale(1.25);
      }
    }
  }

  &.is-disabled,
  &[disabled] {
    opacity: 0.4;

    // DISABLED VALUE STATE
    .range__value {
      position: relative;

      &::before {
        content: 'minus';
        font-size: 1.5rem;
      }

      > input[type='text'] {
        display: none;
      }
    }

    input[type='range']::-webkit-slider-thumb {
      background-color: $bglight-primary-grey;
    }

    input[type='range']::-moz-range-thumb {
      background-color: $bglight-primary-grey;
    }

    input[type='range']::-ms-thumb {
      background-color: $bglight-primary-grey;
    }
  }

  // FORM EXTENSION
  // This feels one off. JJ 4-18-16
  // TODO: target all forms as a base using
  // selectors, we can get rid of all the crap to
  // set this up
  .value-ext {
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    font-size: 14px;
    min-height: 36px;
    outline: 0;
    padding: 0 10px;
    text-align: left;
    transition: border 0.3s ease, background-color 0.3s ease;
    width: 100%;

    &:focus,
    &:hover {
      background-color: $white;
      border: 1px solid $lp-blue;
    }
  }
}

input[type='range'],
.slider {
  //CHROME/SAFARI
  &::-webkit-slider-runnable-track {
    background: $bglight-primary-grey;
    border-radius: 4px;
    cursor: pointer;
    height: 4px;
    width: 100%;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: $white;
    border-radius: 50%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    cursor: grab;
    height: 18px;
    margin-top: -7px;
    position: relative;
    transition: all 0.3s;
    width: 18px;

    &:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
      transform: scale(20/18);
    }
  }
  //FIREFOX
  &::-moz-range-track {
    background: $bglight-primary-grey;
    border-radius: 4px;
    cursor: pointer;
    height: 4px;
    width: 100%;
  }

  &::-moz-range-thumb {
    background: $white;
    border: 0 solid transparent;
    border-radius: 50%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    height: 18px;
    transition: box-shadow 0.3s ease;
    width: 18px;

    &:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
    }
  }

  &::-moz-focus-outer {
    border: 0;
  }
  // MS
  &::-ms-track {
    background: transparent;
    border-color: transparent;
    border-radius: 4px;
    border-width: 18px 0;
    color: transparent;
    cursor: pointer;
    height: 4px;
    width: 100%;
  }

  &::-ms-fill-lower {
    background: $bglight-primary-grey;
    border: 0;
  }

  &::-ms-fill-upper {
    background: $bglight-primary-grey;
    border: 0;
  }

  &::-ms-thumb {
    background: $white;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    height: 18px;
    transition: box-shadow 0.3s ease;
    width: 18px;

    &:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
    }
  }

  &:focus {
    &::-ms-fill-lower {
      background: $bglight-primary-grey;
    }

    &::-ms-fill-upper {
      background: $bglight-primary-grey;
    }
  }
}

// DARK THEME
.range {
  // DARK THEME
  &--dark {
    color: $bgdark-primary-grey;

    input[type='range'],
    .slider {
      &::-webkit-slider-runnable-track {
        background: $bgdark-secondary-grey;
      }
    }

    .value-ext {
      color: $bgdark-primary-grey;

      &:hover,
      &:focus {
        background-color: transparent;
        color: $white;
      }
    }
  }
}
