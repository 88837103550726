.lp-image-overlay-wrapper {
  .lp-image-overlay-caption {
    display: none;
  }

  &:hover {
    .lp-image-overlay-caption {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background: $lp-indigo50;

      span {
        color: $white;
        font-size: 2em;
      }
    }
  }

  .widget--clicked & {
    &:hover {
      .lp-image-overlay-caption {
        display: none;
      }
    }
  }
}

.lp-image-react-placeholder {
  position: relative;
  min-height: 168px;
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: 1;
  color: $lp-indigo-new;
  background-color: $white;
  text-align: center;
  transition: color 0.3s ease;
  justify-content: center;
  border-radius: 6px;

  // border
  &:before {
    content: '';
    border-radius: 6px;
    border: 1px dashed $lp-indigo-new;
    position: absolute;
    top: 12px;
    left: 12px;
    bottom: 12px;
    right: 12px;
  }

  &:hover {
    &:before {
      border-style: solid;
    }
  }
}

.lp-image-placeholder-svg {
  height: 50%;
  width: 50%;

  .a {
    fill: $lp-indigo50;
  }
}

.lp-image-react-placeholder-text {
  position: absolute;
  bottom: 24px;
  left: 2px;
  right: 2px;
}
