.lp-sup-switch {
  display: inline-block;
  position: relative;
  width: 41px;

  &-container {
    align-items: center;
    display: flex;
    font-size: 0.875rem; //14px
    justify-content: space-between;
    margin: 6px 24px;

    &:first-child {
      margin: 12px 24px 6px;
    }
  }

  &-label {
    color: $bgdark-primary-grey;
    cursor: pointer;

    &:hover {
      color: $white;
    }
  }
}
