/* To be used in conjuction with page-transition.js [src/attributes/page-transition.js]
  usage:
  <require from="./attributes/page-transition"></require>
  <div class="slide-container">
    <div class="slide-container__slide slide-container__slide--email-settings" page-transition="current.bind: activeSlide; total.bind: 4; index.bind: 0; transition-in.bind: 'indexBased'">
    ...
    </div>
  </div>
  */
.slide-container {
  height: 100%;
  margin: 0;
  position: relative;
  transform: translate3d(0, 0, 0);
  width: auto;

  &__slide {
    display: none;
    height: 100%;
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    width: 100%;

    &.pane-section--current {
      display: block;
    }
  }
}
