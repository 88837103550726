// VARIABLES
// variables for the build
// NOTE: these can be moved into a global var partial
// media query breakpoints
$break-nav: 95em; // 1520
$break-xl: 80em; // 1280
$break-lg: 64em; // 1024
$break-md: 48.625em; // 769
$break-sm: 30em; // 480

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-base: 'Akkurat', 'Roboto', 'Helvetica', sans-serif;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

// Lego Shadows
$shadow-default: 0 1px 3px rgba(0, 0, 0, 0.32);
$shadow-default-02: 0 4px 6px rgba(0, 0, 0, 0.26);

// LE&DS Shadows
$leads-shadow-l1: 0 0 2px 0 rgba(0, 0, 0, 0.25), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
$leads-shadow-l2: 0 0 6px 0 rgba(0, 0, 0, 0.25), 0 3px 6px 0 rgba(0, 0, 0, 0.2);
$leads-shadow-l3: 0 0 6px 0 rgba(0, 0, 0, 0.25), 0 8px 10px 0 rgba(0, 0, 0, 0.25);
$leads-shadow-l4: 0 0 10px 0 rgba(0, 0, 0, 0.25), 0 12px 18px 0 rgba(0, 0, 0, 0.3);
$leads-shadow-l5: 0 0 12px 0 rgba(0, 0, 0, 0.25), 0 16px 18px 0 rgba(0, 0, 0, 0.35);

// Timing
$transition-medium: 0.3s ease-out;

// Z-index Master List
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$z-index-widget: 1;
// This is a SASS map (see http://sass-lang.com/documentation/file.SASS_REFERENCE.html#maps)
// NOTE: Prettier is really mangling this map right now.
/* prettier-ignore */
$layer: (
  // main nav
  main-navigation: 2000,

  // recommendations
  recommendations: 1,
  recommendations-toggle: 2,

  // publish actions
  publish-actions: 2,
  publish-actions-clickshield: 1,

  // zapier modal
  zapier-modal: 10000,

  // resize
  dropzone: 9999999,

  // PANES
  // =====

  // general
  side-panes: 100,
  pane-section-current: 1,


  // layout pane
  editable-name-form: 100,
  layout-pane-confirm: 100,
  layout-pane-color-picker: 100,

  // off-canvas-editor-pane
  off-canvas-editor-pane: 98,

  // off-canvas-settings-pane
  off-canvas-settings-pane: 98,

  // page-style-pane
  page-style-pane-color-picker: 100,

  // widget-sidebar
  widget-sidebar-open-off-canvas-pane: 100,
  widget-sidebar-open-side-nav: 100, // down from 101

  // editor pane
  editor-pane-open: 100,

  side-nav: 101,
  click-shield-active-side-nav: 101,

  // template editor
  template-editor: 0,
  template-header: 1,
  template-pane: 0,
  template-pane-after: 10,

  // BOXES
  // =====

  // general
  box-delete-warning: 12,

  // section
  section-placeholder: 0,
  section-settings-item-first: 10,
  section-shield-non-placeholder: 1,
  section-shield: 4,
  new-section-hover: 2,
  widget-section-wrapper: 11,

  // layout
  layout-active-container: 1,
  layout-active-container-after: 0,

  // column
  column-placeholder: 1,

  // widget / composite
  // TODO: In *subsequent* PR, change shield-is-applied as needed
  // It has issues with the new sites z-indices for resize handles
  shield-is-applied: 4,
  crucible-shield-is-applied-hover: 1,
  shield-is-applied-widget: 14,
  button-container: 1,
  button-menu-closed: -1,
  button-menu: -1,
  button-menu-overlay: 1,
  widget: $z-index-widget,
  widget-form-inputs: $z-index-widget + 1,
  widget-hover: 9,
  widget-clicked: 11,
  image-overlay-trump: 9,
  widget-needs-auth: -10, // crude attempt to make unauthed user unable to see builder?
  widget-success: -10,
  widget-column-image-and-text-image: 3,

  // WIDGET OPTIONS
  widget-options: 10,
  widget-options-classname: 200,
  widget-options-top-icon: 2,
  widget-options-top-item-after: 1,

  // integrations
  integration-select-before: 0,
  integration-select-after: 1,

  // flyouts
  flyout-list: 9,

  // remove-modal
  remove-modal: 1,
  remove-modal-before: -1,

  // tag-integration-list
  tag-integration-search-results: 9,

  //fields
  sp-color-field: -1, // TODO: is this class this is applied to used anywhere?

  // preview
  device: 10,

  // color setting
  swatch-background: -1,

  // shields
  galactic-shield: 99,

  // page-setup
  page-setup-form: 0,
  page-setup-input: 1,
  template-loading: -1,
  template-loading-visible: 1,
);

@function layer($name) {
  @if map-has-key($layer, $name) {
    @return map-get($layer, $name);
  }

  // Warns get ignored and these errors are easy to fix,
  // so leave it as an error
  @error 'The key \'#{$name}\' is not in the map \'$layer\'';
}
