@import 'settings.colors';

.close-btn {
  color: $bgdark-primary-grey;
  cursor: pointer;
  font-size: 1.125rem;
  margin-left: 0.5em;
  transition: color 200ms ease;

  &:hover {
    color: $white;
  }
}

.close-btn--has-remove-modal {
  position: relative;

  .remove-modal {
    border-radius: 24px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.8);
    transform: translate(45px, 10px);

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  .remove-modal__prompt,
  .remove-modal__cancel {
    background: $white;
    color: $greyscale-03;
  }
}

.settings-menu {
  border-bottom: 1px solid $bgdark-secondary-grey;
  display: flex;
  flex: 1 0 auto;
  list-style: none;
  margin: 0;
  padding: 5px 0 0;
}

.settings-menu__item {
  color: $bgdark-secondary-grey;
  cursor: pointer;
  font-size: 0.75rem;
  margin: 0 24px 12px 0;
  position: relative;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;

  &::after {
    border-bottom: 4px solid transparent;
    bottom: -13px;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    transition: border-color 0.2s linear;
  }

  &:hover {
    color: $white;
  }

  &.item-is-active {
    color: $white;

    &::after {
      border-color: $lp-blue;
    }
  }
}

.settings-content {
  margin: 24px 0 0;
  padding: 0;
  width: 100%;
  // overflow: hidden;
  // overflow-y: auto;
  // overflow-x: hidden;
  // position: relative;
}

.settings-content__chunk {
  display: none;
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &.pane-section--current {
    display: block;
  }
}

.settings-content__chunk__title {
  border-bottom: 1px solid $greyscale-03;
  color: $bgdark-primary-grey;
  font-size: 1.125rem;
  margin: 0 0 20px;
  padding: 0;
}
