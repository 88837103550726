leadbox-widgets-pane,
leadbox-layout-pane {
  &.slide-container__slide {
    width: 415px;
  }
}

.oc-leadbox-pane {
  @extend .oc-editor-pane;
}

.pane {
  color: $bgdark-primary-grey;
  padding: 0 24px;

  &__header {
    align-items: center;
    display: flex;
    height: $lp-header-height;
    justify-content: space-between;
    margin: 0;
    padding: 20px 0;

    &__actions {
      padding: 20px 0;
    }

    h4 {
      color: $bgdark-primary-grey;
      font-size: 20px;
      margin: 0;
      padding: 0;
      white-space: nowrap;
    }
  }

  &__form {
    padding: 0;
  }

  &__body {
    display: flex;
    flex: 1 1 auto;
    height: calc(100% - 72px);
    padding: 0;
  }
}

.data-tip-wrap {
  &::after {
    text-align: center;
    width: 202px;
  }
}

.lp-icon-help {
  cursor: pointer;
}
