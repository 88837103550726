@import 'settings.colors';

@mixin corner-ribbon(
  $text: 'Beta',
  $fontSize: 12px,
  $textColor: $leads-grey-darker,
  $lineHeight: 15px,
  $backgroundColor: $leads-green-light,
  $width: 80px,
  $top: 7px,
  $left: -25px
) {
  &::after {
    width: $width;
    background: $backgroundColor;
    content: $text;
    color: $textColor;
    font-size: $fontSize;
    font-weight: bold;
    line-height: $lineHeight;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    left: $left;
    top: $top;
    transform: rotate(-45deg);
  }
}

@mixin submenu-item() {
  align-items: start;
  color: $leads-grey-darker;
  display: flex;
  font-size: 15px;
  line-height: 24px;
  outline: none;
  padding: 12px 16px;
  white-space: normal;
  width: 100%;

  &:hover,
  &:focus {
    color: $leads-grey-darker;
    background-color: $lp-light-grey;
  }
}
