.error {
  align-items: center;
  background-color: $base-grey;
  display: flex;
  flex-flow: nowrap column;
  font-weight: 300;
  height: 100vh;
  justify-content: center;

  &__title {
    animation: errorTitleJump 2s infinite;
    color: $lp-red;
    font-size: 8rem;
    font-weight: 300;
    line-height: 1;
  }

  &__text {
    font-size: 1.25rem;
  }

  .title-shadow {
    animation: errorTextJump 2s infinite;
    background-color: $greyscale-08;
    border-radius: 50%;
    height: 9px;
    margin: 12px auto 24px;
    opacity: 0.8;
    width: 131px;
  }
}

@keyframes errorTitleJump {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-12px);
  }
}

@keyframes errorTextJump {
  0%,
  100% {
    width: 131px;
  }

  50% {
    width: 100px;
  }
}
