.form-data {
  &__title {
    display: block;
    font-size: 1rem; //16
    font-weight: 300;
    padding: 0 0 18px 6px;
    text-align: left;

    &:not(:first-child) {
      padding-top: 24px;
    }
  }

  &__section {
    padding: 0 6px;

    .section-text {
      display: block;
      margin-bottom: 6px;
    }
  }

  &__error {
    display: flex;
    padding: 0 0 18px 6px;
    text-align: left;
    font-size: 14px;

    i {
      margin: -8px 6px 0 0;
    }
  }

  .name-your-form {
    font-size: 1rem;
  }
}

//General form-wizard styles
form-wizard {
  .oc-editor-pane__nav {
    padding-bottom: 24px;
  }

  .ui-tabs > li {
    padding: 6px 0;
  }
}

.form-wizard__body {
  margin-bottom: 36px;
  padding: 0 6px;
}

.form-wizard__footer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}

.first-step {
  justify-content: flex-end;
}
