// TODO: revisit and clean up when old integrations Module is removed
.ai-dialog-open,
ai-dialog {
  overflow: hidden;
}

.integrations-open {
  overflow: auto;
}

body:not(.integrations-open) {
  ai-dialog-overlay {
    background-color: rgba(2, 0, 32, 0.9);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.5s ease-in-out;
  }

  ai-dialog-overlay.active {
    opacity: 1;
  }

  ai-dialog-container {
    align-items: center;
    bottom: 0;
    display: flex;
    height: 100vh;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    outline: 0;
    overflow-y: auto;
    padding: 12px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(10%);
    transition: opacity 0.3s linear, transform 0.5s ease-in-out;
  }

  ai-dialog-container.active {
    opacity: 1;
    transform: translateY(0);

    @media screen and (max-height: 800px) {
      padding-top: 80px;
    }
  }

  ai-dialog {
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    display: inline-block;
    flex: 0 1 810px;
    margin: auto;
    padding: 0;
    position: static;
  }

  ai-dialog > ai-dialog-header {
    background-color: $base-grey;
    border-bottom: 1px solid $bgdark-primary-grey;
    display: block;
    padding: 36px 36px 0;
    position: relative;
  }

  ai-dialog > ai-dialog-header > button {
    background: 0 0;
    border: 0;
    color: $bgdark-primary-grey;
    cursor: pointer;
    float: right;
    font-size: 29px;
    line-height: 1;
    margin: 0;
    padding: 0;
    position: relative;
    right: -20px;
    top: -30px;
    transition: color 0.25s ease;

    &:hover {
      color: $bgdark-secondary-grey;
    }
  }

  ai-dialog > ai-dialog-body,
  ai-dialog > ai-dialog-footer {
    display: block;
    padding: 24px 36px;
  }

  ai-dialog > ai-dialog-body.leadbox,
  ai-dialog > ai-dialog-footer.leadbox {
    padding: 6px;
  }

  ai-dialog > ai-dialog-footer button:focus,
  ai-dialog > ai-dialog-header > button:focus {
    outline: 0;
  }

  ai-dialog > ai-dialog-footer {
    background-color: $base-grey;
    border-top: 1px solid $bgdark-primary-grey;
    text-align: right;
  }
}
