html {
  background: $base-grey;
  font-family: 'Akkurat', Roboto, sans-serif;
  position: fixed !important; // Fix Safari scroll bug (LPE-3745)
}

body {
  height: 100vh;
}

strong {
  font-family: inherit;
}

// Override the hover behavior on the powered-by bar.
#powered-by-container {
  .powered-by__sub_container {
    top: 0px; // Disable hover.
    overflow: visible; // Allow tooltip to render above page.
  }
  .powered-by__top_container {
    min-height: 36px; // Disable hover.
    position: relative; // For the tooltip.
  }
  .powered-by__top_container::before {
    content: 'Manage badge display in Account Settings';
    z-index: 1; // Ensure this is above text in the footer.
    position: absolute;
    top: -16px;
    transform: translateY(-50%);
    width: 243px;
    height: 24px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    color: #0f0c09;
    text-align: center;
    font-family: 'Open Sans', 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 24px;
    align-items: center;
    justify-content: center;
    display: none;
  }
  .powered-by__top_container:hover::before {
    display: flex;
  }
  .powered-by__bottom_container {
    display: none; // Disable hover.
  }
}

.panes-wrapper {
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 72px);
  position: relative;
}

.side-nav {
  background: $lp-greyDarkest;
  flex: 0 0 72px;
  display: flex;
  opacity: 1;
  position: relative;
  visibility: visible;
  z-index: layer(side-nav);
  border-right: 1px solid $lp-greyDarker;
  padding: 12px 0;

  &--crucible-render {
    background: $lp-greyDarkest;
  }

  [data-tips].data-tip-right {
    &:hover {
      &:before {
        left: calc(100% + 13px);
      }
      &:after {
        left: calc(100% + 18px);
      }
    }
  }

  .click-shield-is-active & {
    z-index: layer(click-shield-active-side-nav);
  }
}

// .widget-pane__outer-wrapper deliberately omitted as hieght: 100vh;
// causes it issues on vertically short screens
page-style-pane,
.section-pane__outer-wrapper {
  overflow-y: auto;
}

.section-pane__outer-wrapper,
.widget-pane__outer-wrapper {
  height: calc(100vh - 72px); //height of top header
}

.section-pane__outer-wrapper,
.widget-pane__outer-wrapper,
page-style-pane {
  &.slide-container__slide {
    width: 288px; // differs from design but needed for consistent widget experience
  }
}

.section-pane__outer-wrapper,
.widget-pane__outer-wrapper,
leadbox-widgets-pane {
  .slide-container {
    margin-right: -15px; //creating space for perfect scrollbar
  }

  .slide-container__slide {
    &.pane-section--current {
      align-content: flex-start;
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}

.widget-pane__outer-wrapper,
.widget-pane {
  padding: 0 24px;

  &__close {
    text-align: right;
    width: 100%;
  }

  &__header {
    align-items: center;
    display: flex;
    margin: 0;
  }

  &__tabs {
    align-items: flex-end;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    flex: 1 1 50%;
    height: 24px;
    justify-content: space-between;
    width: 100%;
  }
}

// Overriding some LEADs styles.
.widget-pane__outer-wrapper {
  .widget-container {
    .widget-container__show-more-link {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      color: $base-grey-hover;
      padding-bottom: 21px;

      &:hover,
      &:focus {
        color: $base-grey;
      }
    }
  }
}

.widget-container {
  height: calc(100% - 72px);

  // this class gets removed when search becomes a thing
  // above that value should be 142px with search enabled
  &--no-search {
    margin-top: 0;
  }
}

.pane-section--current {
  visibility: visible;
  z-index: layer(pane-section-current);
}

.template-pane {
  flex: 1 1 calc(100% - 68px);
  height: 100%;
  position: relative;
  transform: translate3d(0, 0, 1px);
  transition: all 0.4s ease-in-out;
  width: 100%;
  // overriding z-index of pane-manager
  z-index: layer(template-pane);

  &::after {
    background: rgba(0, 0, 0, 0.7);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.5s ease-in;
    visibility: hidden;
    width: 100%;
    z-index: layer(template-pane-after);
  }
}

.template-pane__editor {
  height: calc(100vh - 72px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  width: 100%;

  .bonus-space {
    background: $lp-greyDarkest;
    height: 30vh;
    position: relative;
    text-align: center;

    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 7vh;
    }

    .emoji-icon {
      font-size: 2rem;
    }

    .bonus-message-container {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.5rem;
      margin-top: 0.5rem;
    }
  }

  &--active-leadbox,
  &--bar {
    overflow-y: hidden;
  }
}

.galactic-shield {
  bottom: -9999px;
  left: -9999px;
  position: fixed;
  right: -9999px;
  top: -9999px;
  z-index: layer(galactic-shield);
}

.box-outline {
  box-shadow: inset $lp-outline-toolbar 0 0 0 4px, inset rgba(255, 255, 255, 0.4) 0 0 0 5px;
}

.optimized-box-outline {
  border: 5px solid;
  border-image: linear-gradient(to right, #65e0fe 0%, #7059ff 50%, #fe51c8 100%) 1;
}

.shape-wrapper {
  overflow: hidden;
}

.is-hovered .shape-wrapper::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-left: 4px solid $lp-outline-toolbar;
  border-right: 4px solid $lp-outline-toolbar;
  border-bottom: 4px solid $lp-outline-toolbar;
}

.is-hovered .shape-wrapper--flipped::before {
  content: none;
}

.is-hovered .shape-wrapper--flipped::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-left: 4px solid $lp-outline-toolbar;
  border-right: 4px solid $lp-outline-toolbar;
  border-bottom: 4px solid $lp-outline-toolbar;
}

.box-delete-warning {
  &::after {
    background-color: transparentize($lp-red, 0.4);
    background-image: repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 12px,
      rgba(0, 0, 0, 0.1) 12px,
      rgba(0, 0, 0, 0.1) 24px
    );
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: layer(box-delete-warning);
  }
}

/* find  a better home for this */
.box-background-img {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.template-loading {
  background: $white;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  z-index: layer(template-loading);

  &.is-visible {
    opacity: 1;
    z-index: layer(template-loading-visible);
  }

  .ui-loading {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

// Tooltip Lego Overrides
[data-tips] {
  &:after {
    border-radius: 3px;
    pointer-events: none;
  }
}

.ui-tip__content {
  border-radius: 3px;
  font-size: 0.875rem;
  padding: 9px 18px;
}
