// TODO: HR styles are affecting line widgetMenu ~Fogo
hr {
  background-color: transparent;
  margin-bottom: 0;
}

// SUPERSCRIPT TRUMP
sup {
  font-size: 1ex !important; // YES THIS IS IMPORTANT
  top: -0.9em !important; // DONT JUDGE ME
}

// THIS FILE IS FOR LEGO SPECIFIC
// ADDITIONS THAT COULD BE ADDED TO LEGO
// THESE ARE THINGS NOTICED NOT IN LEGO DOCS
.ui-btn--circle {
  &.is-active {
    border-color: $lp-blue;
    color: $lp-blue;
  }
}

// THIS WRAPS CHECKBOXES AND SEEMS
// TO BE CONSISTENT
.lp-checkbox {
  margin: 12px 0;
}

// REMOVES DEFAULT MARGIN
.ui-label.label-remove-margin {
  margin-bottom: 0;
}

// THESE COULD BE ADDED TO FORMS
// INSIDE LEGO

/* add a note to the input */
.lp-form-note {
  color: $bgdark-secondary-grey;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.24px;
  margin-top: 6px;
  display: block;

  &--no-margin {
    margin: 0;
  }

  &--icon::before {
    color: $lp-blue;
    content: '\e206';
    font-family: 'LeadPages-Icons';
    font-size: 1.2rem;
    height: 14px;
    margin-right: 6px;
    overflow: hidden;
    position: relative;
    top: 2px;
    transition: all 0.25s ease;
    width: 14px;
  }

  .ui-link {
    color: $bgdark-primary-grey;
    font-size: 1em;

    &:hover {
      color: $white;
    }
  }
}

.lp-form-desc {
  color: $bgdark-secondary-grey;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.714285714; // 24/14
  margin-bottom: 24px;

  &--margin-top {
    margin-top: 24px;
  }

  a {
    color: $bgdark-primary-grey;

    &:hover {
      color: $white;
    }
  }
}

// First Pass at a hide/show helper pattern
.is-hidden {
  opacity: 0;
  transition: all 0.5s ease;
}

// Grid List in infocard of fields for forms to adjust width
.field-list {
  .ui-gl-grid__item {
    flex-basis: 75px;
  }
}

// This seems pretty intense to target all li's with Lego.  It can be discussed
// but we handle a lot of the spacing in specific lists depending on their context
ul > li,
ol > li {
  margin: 0;
}

// PILLS IN SIDEBAR USED AS BUTTONS
// SADFACE
.ui-pill.ui-pill--b3 {
  border: 0;
}
// This is to deal with super long titles in the grid.  Right now if text is one long word
// it stretches the grid item and can break the flow
.ui-grid-list .ui-gl-grid .ui-option {
  max-width: 75px;
  word-wrap: break-word;
}

// duration field styles for now -- Extract this out to Lego
.duration-field {
  color: $bgdark-primary-grey;
  font-size: 14px;
  height: 0;
  overflow: hidden;
  transition: padding 0.3s ease;

  &.is-visible {
    padding: 12px 0;
  }

  input {
    background: transparent;
    border: 1px solid $bgdark-primary-grey;
    border-radius: 3px;
    color: $white;
    text-align: center;
    width: 36px;
  }
}

.ui-label--search--clear {
  padding-right: 30px;
}

.ui-search-clear-btn {
  font-size: 1rem;
  height: 1em;
  line-height: 1;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

// Styles placeholder text in dropdown-select
// Used in select-integration-list component
.dropdown-select__placeholder {
  opacity: 0.4;
}
