%navigation__group-border {
  background-color: #dedfe2;
  content: '';
  height: 36px;
  margin: 0 6px;
  width: 1px;
}

.navigation__group {
  align-items: center;
  display: flex;

  &--border-left {
    &::before {
      @extend %navigation__group-border;
    }
  }

  &--border-right {
    &::after {
      @extend %navigation__group-border;
    }
  }
}
