.follow-up {
  &.ui-form--dark &__section .ui-label {
    &:hover .ui-input.is-invalid {
      border-color: $lp-red;
    }
    .ui-input.is-invalid {
      border-color: $lp-red;
    }
  }

  &__input-info {
    margin-top: 4px;
    font-size: 0.9em;
    font-weight: bold;
  }

  &__section {
    &:not(:first-child) {
      padding: 12px 0;
    }
  }

  &__title {
    font-size: 1rem; //16
    font-weight: 300;
    padding: 24px 0 18px;
    text-align: left;
  }

  &-locations {
    padding-left: 6px;
  }

  .section-text {
    display: block;
    margin-bottom: 6px;
    padding-left: 6px;

    &--secondary {
      color: $bglight-secondary-grey;
      font-size: 15px;
    }
  }

  .lp-icon-view {
    color: $bgdark-primary-grey;
    cursor: pointer;
    margin: 0 6px;
    transition: color 300ms ease;

    &:hover {
      color: $white;
    }
  }

  .ui-radio {
    margin-bottom: 12px;
    margin-right: 0;
  }

  .info-card-body--leadmagnet {
    .slide-container {
      height: 84px;
      transition: height 300ms ease;

      &.is-dropdown-open {
        height: 180px;
      }
    }
  }

  .info-card-internal {
    .dropdown-list__item,
    .dropdown-select__item {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .leadmagnets-list {
    .info-card__list-item,
    a,
    .ui-btn-plain,
    .lp-icon {
      font-size: 0.75rem;
    }
  }

  .alternate-leadpage {
    margin-right: 6px;

    .leadpage-name {
      color: $white;
      display: inline-block;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3;
      max-width: 175px;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
    }

    &__leadpage-selection {
      padding-bottom: 12px;
    }
  }

  .alternate-external {
    margin-right: 6px;

    .ui-input {
      margin-top: 0px !important;
    }
  }

  .smart-linking {
    border: 1px solid $white;
    border-radius: 3px;
    height: 360px;
    overflow: hidden;
    position: relative;
  }

  .choose-lead-magnet {
    margin-top: 16px;
  }

  .attract-io-message {
    display: flex;
    font-size: 15px;
    line-height: 20px;
    margin-top: 24px;

    &__icon {
      margin-right: 6px;
    }

    &__text {
      margin-top: 5px;
    }

    a {
      color: $leads-blue-light;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $leads-blue;
      }
    }
  }
}
