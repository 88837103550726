// styles are being pulled from off canvas
// this could be dried out but lets wait on
// this until it is closer to done - jason j
// note I did not sass it up so it would be
// easier to see what I used
.oc-editor-pane-wrap {
  flex: 0 0 70px;
  flex-direction: column;
  margin: 0 0 0 -70px;
  padding: 0;
  position: relative;
  transform: translateX(0);
  transition: all 0.4s ease-out;
  z-index: layer(off-canvas-editor-pane);
}

.oc-editor-pane {
  background: $lp-greyDarkest;
  color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 480px;
  overflow-x: hidden;
  overflow-y: auto;
}

.oc-editor-pane-content {
  flex-grow: 1;
}

.oc-editor-pane__header {
  align-items: center;
  display: flex;
  height: $lp-header-height;
  justify-content: space-between;
  margin: 0;
  padding: 20px 24px;

  h4 {
    font-size: 20px;
    margin: 0;
    padding: 0;
  }
}

.oc-editor-pane__body {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  padding: 0 24px;
}

.oc-editor-pane__field-seperator {
  height: auto;
  margin-bottom: 40px;
  position: static;
  width: auto;
}

.make-layouts-break-again {
  width: 100%;
}

.oc-editor-pane__nav {
  display: flex;
  justify-content: flex-start;
  padding: 20px 24px 12px;
}

.editor-menu {
  border-bottom: 1px solid $bgdark-secondary-grey;
  counter-reset: li;
  display: flex;
  flex: 1 0 auto;
  list-style: none;
  margin: 0;
  padding: 5px 0 0;

  &__item {
    color: $bgdark-secondary-grey;
    counter-increment: li;
    cursor: pointer;
    font-size: 0.75rem;
    margin: 0 24px 12px 0;
    padding-left: 24px;
    position: relative;
    text-transform: uppercase;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $white;

      &::before {
        border-color: $white;
      }
    }

    &::before {
      background: transparent;
      border: 1px solid $bgdark-secondary-grey;
      border-radius: 50%;
      content: counter(li);
      height: 18px;
      left: 0;
      line-height: 1.4;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      transition: border-color 0.3s;
      width: 18px;
    }

    &.item-is-active {
      color: $white;

      &::before {
        border-color: $lp-blue;
      }

      &::after {
        border-bottom: 4px solid $lp-blue;
        bottom: -13px;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
      }
    }
  }
}

.editor-content {
  margin: 0 4px 0 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.editor-content__chunk {
  height: 100%;
  padding: 0; //0 20px 0 0;
  visibility: hidden;
  width: 100%;
}

.editor-content__chunk__title {
  border-bottom: 1px solid $greyscale-03;
  color: $bgdark-primary-grey;
  font-size: 1.125rem;
  margin: 0 0 20px;
  padding: 0;
}
