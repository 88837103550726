/* TAKEN FROM LEGOS */
// flyout styling
$flyout-width: 225px !default;
$flyout-toggle-distance: 10px !default;
$flyout-border-radius: 3px !default;
$flyout-border-color: $bgdark-primary-grey !default;
$flyout-hr-color: $bgdark-primary-grey !default;

$flyout-default-bg: $white !default;
$flyout-default-item-color: $bglight-primary-grey !default;
$flyout-default-item-hover: $lp-blue !default;

$flyout-dark-bg: $lp-navy !default;
$flyout-dark-item-color: $bgdark-primary-grey !default;
$flyout-dark-item-hover: $white !default;
$flyout-dark-border-color: transparentize($bgdark-primary-grey, 0.6) !default;

// need to set position relative on the trigger so we can absolutely position the flyout
.ui-flyout {
  cursor: pointer;
  position: relative;

  &.is-open {
    ul {
      display: block;
      opacity: 1;
    }
  }
}

.ui-flyout {
  ul {
    background-color: $flyout-default-bg;
    border: 1px solid $flyout-border-color;
    border-radius: $flyout-border-radius;
    display: none;
    font-size: 0.875rem;
    list-style: none;
    margin: 0;
    opacity: 0;
    padding: 0.75rem 1rem;
    position: absolute;
    right: 50%;
    text-align: left;
    top: calc(100% + #{$flyout-toggle-distance});
    transform: translateX(50%);
    width: $flyout-width;
    z-index: layer(flyout-list);

    li {
      color: $flyout-default-item-color;
      font-weight: 400;
      line-height: 1;
      margin: 0;

      a {
        color: $flyout-default-item-color;
        display: block;
        padding: 0.625rem; // 12px
        text-decoration: none;
        transition: color 0.2s;

        &:hover,
        &:focus {
          color: $flyout-default-item-hover;
          outline: none;
        }
      }
    }

    hr {
      border: 1px solid $flyout-hr-color;
      border-width: 0 0 1px;
      height: 1px;
      margin: 0.625rem 0;
      width: 100%;
    }
  }

  &--justify-right {
    ul {
      right: 0;
      transform: translateX(0);
    }
  }

  &--justify-left {
    ul {
      left: 0;
      right: auto;
      transform: translateX(0);
    }
  }

  &--align-left {
    ul {
      right: calc(
        100% + #{$flyout-toggle-distance}
      ); // variable interpolated inside the calc function
      top: 0;
      transform: translateX(0);
    }
  }

  &--align-right {
    ul {
      left: calc(
        100% + #{$flyout-toggle-distance}
      ); // variable interpolated inside the calc function
      top: 0;
      transform: translateX(0);
    }
  }

  &:focus {
    outline: none;
  }
}

.ui-flyout--dark {
  ul {
    background-color: $lp-greyDarker;
    border-color: $flyout-dark-border-color;

    li {
      color: $flyout-dark-item-color;

      a {
        color: $flyout-dark-item-color;

        &:hover,
        &:focus {
          color: $flyout-dark-item-hover;
        }
      }
    }
  }
}
