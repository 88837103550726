.dialog__title {
  color: $bglight-primary-grey;
  font: 300 26px Roboto;
  margin: 0 0 24px;
  padding: 0;
  text-align: center;

  &--no-tabs {
    margin: 12px 0 0;
  }
}

ai-dialog-body {
  &.slide-container {
    .slide__body {
      overflow: hidden;
      padding: 24px 36px;
      width: 100%;
    }

    .slide__footer {
      align-items: center;
      align-self: flex-end;
      background-color: $base-grey;
      border-top: 1px solid $bgdark-primary-grey;
      display: flex;
      height: 83px;
      justify-content: flex-end;
      margin-top: auto;
      padding: 24px 36px;
      text-align: right;
      width: 100%;
    }
  }
}

ai-dialog-container.active {
  overflow: hidden;
}
