.ui-toast__content {
  padding-left: 12px;
  padding-right: 36px;
  &--with-cta {
    padding-right: 12px;
  }
}

.ui-toast__cta {
  width: 145px;
  padding: 0 36px 0 0;

  p {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  a {
    cursor: pointer;
    margin: 10px 0;
    &:hover {
      // Lego has a hover state on links w/ padding-bottom that causes a shift
      // when two links are on the CTA, so this just removes that padding, and
      // keeps a visual cue for hover.
      padding-bottom: 0px;
      border-bottom: 1px solid blue;
    }
  }
}

.ui-toast__close::after {
  content: '\e070';
  position: absolute;
  top: 2.8px;
  right: 4.5px;
  font-size: 1.7rem;
}
