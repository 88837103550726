$lp-rail-default-opacity: 0 !default;
$lp-rail-container-hover-opacity: 0.1 !default;
$lp-rail-hover-opacity: 0.9 !default;
$lp-bar-bg: transparent !default;
$lp-rail-hover-bg: rgba(255, 255, 255, 0.1) !default;
$lp-scrollbar-y-width: 6px !default;

@mixin styled-scrollbar {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: auto;
  &::-webkit-scrollbar {
    opacity: $lp-rail-default-opacity;
    width: $lp-scrollbar-y-width;
  }

  &:hover {
    &::-webkit-scrollbar {
      opacity: 1;
    }
    &::-webkit-scrollbar-track {
      background-color: $lp-rail-hover-bg;
      opacity: $lp-rail-container-hover-opacity;
    }
    &::-webkit-scrollbar-thumb {
      background: $lp-blue-hover;
    }
  }
}
