.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  justify-content: center;
}

.pagination-nav {
  align-items: center;
  display: flex;

  &__prev-btn,
  &__next-btn {
    background: transparent;
    border: 0;
    color: $bglight-primary-grey;
    opacity: 1;
    outline: none;
    padding: 0;
    transition: all 0.3s linear;
    visibility: visible;

    &:hover {
      color: $lp-blue;
    }

    &.is-disabled {
      color: transparentize($bglight-primary-grey, 0.6);
      opacity: 0;
      visibility: hidden;
    }
  }

  &__prev-btn {
    margin-right: 6px;

    &.is-disabled {
      transform: translateX(100%);
    }
  }

  &__next-btn {
    &.is-disabled {
      transform: translateX(-100%);
    }
  }

  &__gap {
    color: $bglight-secondary-grey;
    cursor: default;
    margin-right: 6px;
  }

  &__item {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 50%;
    color: $bglight-secondary-grey;
    font-size: 14px;
    height: 29px;
    outline: 0;
    padding: 3px;
    position: relative;
    transition: all 0.3s linear;
    width: 29px;

    &:not(:last-child) {
      margin-right: 6px;
    }

    &::after {
      border-radius: 50%;
      bottom: 0;
      box-shadow: 0 0 1px 1px $lp-blue;
      content: '';
      left: 0;
      margin: auto;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: scale(0.8);
      transition: all 0.2s linear;
    }

    &:hover {
      color: $black;

      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.is-active {
      background: $lp-blue;
      color: $white;

      &::after {
        display: none;
      }
    }
  }
}
