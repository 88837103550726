.publish-entity {
  &__button {
    padding-left: 24px !important;
    padding-right: 24px !important;

    &--squared {
      border-radius: 15em 0 0 15em !important;
      padding-right: 16px !important;
    }
  }
}
