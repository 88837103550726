@keyframes success {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }

  25%,
  75% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-15px);
  }
}

.info-card-button {
  min-width: 36px;
  position: relative;
  transition: all 150ms ease;

  &:hover {
    color: $greyscale-06;
  }

  &--save {
    font-size: 0.875rem;
    margin: 0 12px 0 6px;

    &.is-loading,
    &.is-loading:hover {
      background-color: $lp-blue;
    }
  }

  &__content {
    opacity: 0;
    transition: opacity 300ms ease;

    &.default {
      opacity: 1;
    }
  }

  &__state {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }
}
