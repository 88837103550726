.slide-toggle {
  border-bottom: 1px solid transparentize($bgdark-primary-grey, 0.6);
  margin: 0;
  padding: 18px 0;

  &:first-child {
    border-top: 1px solid rgba(177, 186, 204, 0.4);
  }

  &-arrow {
    margin-left: 12px;
    transition: transform 0.1s linear;

    .slide-toggle--is-animating &,
    .slide-toggle--is-open & {
      transform: rotate(-180deg);
    }

    .slide-toggle--is-closed & {
      transform: rotate(0deg);
    }
  }

  &-trigger {
    cursor: pointer;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__actions {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  &__body {
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    .slide-toggle--is-closed > & {
      overflow: hidden;
      transition: max-height 0.2s ease-in-out;
    }

    .slide-toggle--is-open > & {
      overflow: visible;
      transition: max-height 0.2s ease-in-out;
    }
  }
}
