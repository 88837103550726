@import 'settings.colors';
@import 'tools.mixins';

.navigation {
  &__save-status {
    color: rgba(255, 255, 255, 0);
    font-size: 0.8125rem;
    margin: 0 12px;
    position: relative;
  }

  &__save-status-indicator {
    color: $white;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.3s ease;

    &--hidden {
      opacity: 0;
    }

    &--saving {
      font-style: italic;
    }
  }

  &__feedback-tooltip-header {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 6px;
  }

  &__feedback-tooltip-text {
    font-size: 13px;
    line-height: 1.6;
  }
}

.lead-rank-display-options-open {
  @include submenu-item();
}
